'use client'
import { useEffect, useState } from 'react'
import { Modal } from './Modal'
import { Button } from './Button'
import { Wallet } from '../Wallet'

interface Props {
  open: boolean
  onClose: () => void
  currentPaymail: string
}

export function EditPaymailModal(props: Props) {
  const { open, onClose, currentPaymail } = props
  const [error, setError] = useState<string | null>(null)
  const [paymail, setPaymail] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!open) {
      setError(null)
      setPaymail('')
      setLoading(false)
    }
  }, [open])

  const updatePaymail = async () => {
    setError(null)
    setLoading(true)
    try {
      await Wallet.changePaymail(paymail)
      onClose()
    } catch (err: any) {
      setError(err.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      title="Change your paymail"
      open={open}
      onClose={onClose}
      showCloseButton={true}
    >
      <div className="mt-4 flex justify-center">
        <div className="flex max-w-xs flex-col self-center pb-5">
          <div className="flex items-center justify-center space-x-2">
            <input
              className="input my-4 max-w-[140px] text-right"
              type="text"
              placeholder={`${currentPaymail.split('@')[0]}`}
              onChange={(e) => setPaymail(e.target.value)}
              value={paymail}
            />
            <div className="">@canonic.xyz</div>
          </div>
          <div className="flex justify-center">
            <Button
              disabled={!paymail}
              loading={loading}
              onClick={updatePaymail}
              className="btn-primary mt-4 px-10"
            >
              Save
            </Button>
          </div>
          {!!error && <div className="mt-2 text-red-500">{error}</div>}
        </div>
      </div>
    </Modal>
  )
}
