import React from 'react'
import ExternalLink from './ExternalLink'
import * as Sentry from '@sentry/react'
import FeedbackButton from './FeedbackButton'

interface Props {
  children: React.ReactNode
}

function Fallback() {
  return (
    <div
      id="error-boundary-feedback-widget"
      className="flex h-screen w-screen flex-col items-center justify-center p-4 text-center"
    >
      <div className="text-4.5xl dark:text-theme-body-white text-black">
        Oops, something went wrong.
      </div>
      <div className="text-center text-lg">
        Please try refreshing. If that doesn't work, contact support at{' '}
        <ExternalLink
          href="mailto:info@canonic.xyz"
          className="text-theme-purple dark:brightness-125"
        >
          info@canonic.xyz
        </ExternalLink>{' '}
      </div>
      <div className="mt-6">
        <FeedbackButton className="btn-primary" buttonLabel="Report this bug" />
      </div>
    </div>
  )
}

export default function CatastrophicErrorBoundary({ children }: Props) {
  return (
    <Sentry.ErrorBoundary fallback={<Fallback />}>
      {children}
    </Sentry.ErrorBoundary>
  )
}
