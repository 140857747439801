'use client'
import { Dialog, Transition } from '@headlessui/react'
import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Fragment, useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import logo from './logo_only.png'

export interface BaseModalProps {
  open: boolean
  onClose: () => void
}

interface ModalProps extends BaseModalProps {
  children: React.ReactNode
  showCloseButton?: boolean
  title?: string
  smallTitle?: boolean
  /**
   * When set to `true`, clicking outside the modal will not dismiss it.
   */
  preventOutsideClick?: boolean
  unmount?: boolean
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl'
  testId?: string
  withLogo?: boolean
  success?: boolean
}

export function Modal({
  open,
  onClose,
  children,
  showCloseButton = true,
  preventOutsideClick = false,
  title,
  unmount = true,
  size = 'xl',
  testId,
  withLogo,
  smallTitle,
  success,
}: ModalProps) {
  const MAX_WIDTHS: Record<typeof size, string> = {
    sm: 'max-w-sm',
    md: 'max-w-md',
    lg: 'max-w-lg',
    xl: 'max-w-xl',
    '2xl': 'max-w-2xl',
  }

  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const [didResetScroll, setDidResetScroll] = useState(false)

  useEffect(() => {
    if (open && !didResetScroll) {
      setTimeout(() => {
        if (scrollContainerRef.current) {
          scrollContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' })
          setDidResetScroll(true)
        }
      }, 1)
    }

    if (!open && didResetScroll) {
      setDidResetScroll(false)
    }
  }, [open, didResetScroll])

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [open])

  return (
    <Transition show={open} unmount={unmount}>
      {/* Keep this onClose set to null so clicking outside the modal doesn't dismiss it */}
      <Dialog
        onClose={() => (preventOutsideClick ? null : onClose())}
        className="relative z-50"
        open={open}
        unmount={unmount}
        data-testid={testId}
      >
        {/* Backdrop */}
        <Transition.Child
          unmount={unmount}
          className="fixed inset-0 bg-black/30 transition-all dark:bg-black/95"
          enter="ease-out duration-150 transition-all"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in transition-all duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        />

        <div
          ref={scrollContainerRef}
          className="fixed inset-0 overflow-y-scroll sm:p-10"
          style={{ WebkitOverflowScrolling: 'touch' }}
        >
          <div className="flex min-h-full items-center justify-center px-2 sm:px-0">
            <Transition.Child
              unmount={unmount}
              as={Fragment}
              enter="ease-out duration-150"
              enterFrom="opacity-0 translate-y-full"
              enterTo="opacity-100 translate-y-none"
              leave="ease-in duration-100"
              leaveFrom="opacity-100 translate-y-none"
              leaveTo="opacity-0 translate-y-full"
            >
              <Dialog.Panel
                className={`w-full ${
                  MAX_WIDTHS[size]
                } xs:px-10 bg-theme-body-white text-theme-body-gray relative mx-auto rounded-lg px-4 py-8 text-lg sm:relative dark:bg-gray-800 dark:text-gray-300 ${cx(
                  { 'mt-6': !withLogo, 'mt-20': withLogo },
                )}`}
              >
                <>
                  {withLogo && typeof logo === 'string' && (
                    <div className="absolute inset-x-0 top-0 flex -translate-y-1/2 items-center justify-center">
                      <img
                        src={logo}
                        alt="canonic"
                        className="bg-theme-body-white z-10 mb-1.5 h-28 w-28 rounded-lg border-gray-200/50 p-1 dark:border-black/50 dark:bg-gray-800"
                      />
                      <div className="absolute inset-x-0 bottom-0 flex h-1/2 justify-center">
                        <div className="h-full w-32 rounded-b-xl bg-gray-200 dark:bg-black" />
                      </div>
                    </div>
                  )}
                  <button
                    onClick={onClose}
                    className={`${cx({
                      'sm:hidden': !showCloseButton,
                    })} absolute right-3 top-3 block p-1.5`}
                    type="button"
                    data-testid="closeModalButton"
                  >
                    <XMarkIcon className="dark:text-theme-body-white h-7 w-7 text-black sm:h-5 sm:w-5" />
                  </button>
                  {title && (
                    <Dialog.Title
                      className={`dark:text-theme-body-white flex items-center justify-center text-center text-black ${cx(
                        {
                          'mt-10': withLogo,
                          'mt-6': !withLogo,
                          'text-[2rem]': !smallTitle,
                          'text-xl': smallTitle,
                        },
                      )}`}
                    >
                      {title}
                      {success && (
                        <CheckCircleIcon className="ml-2 h-8 w-8 text-green-400" />
                      )}
                    </Dialog.Title>
                  )}
                  {children}
                </>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
