'use client'
import QRCode from 'qrcode.react'
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline'
import { RadioGroup } from '@headlessui/react'
import cx from 'classnames'
import { Wallet } from '../Wallet'
import { CopyToClipboard } from './CopyToClipboard'
import { LoadingIndicator } from './LoadingIndicator/LoadingIndicator'
import { Tooltip } from './Tooltip'
import { addressMainnetToTestnet, bchaddr } from '@canonic/lib'
import { Ticker } from '@canonic/types'

export interface ReceiveBitcoinProps {
  paymail: string | undefined | null
  /** Include signet with the default tickers */
  showSignet?: boolean
  tickers?: Ticker[]
}

export function ReceiveBitcoin(props: ReceiveBitcoinProps) {
  const { paymail } = props
  const [standardAddress, setStandardAddress] = useState<string | null>(null)
  const [cashAddr, setCashAddr] = useState('')
  const [signetAddr, setSignetAddr] = useState('')
  const [showPaymail, setShowPaymail] = useState(false)
  const [ticker, setTicker] = useState<Ticker>('BTC')

  useEffect(() => {
    if (paymail) {
      try {
        const address = Wallet.getChangeAddress()
        setStandardAddress(address)
        setCashAddr(bchaddr.toCashAddress(address))
        setSignetAddr(addressMainnetToTestnet(address))
      } catch (err) {
        console.error(err)
      }
    }
  }, [paymail])

  const defaultTickers = ['BTC', 'BCH', 'BSV']

  const address =
    ticker === 'BCH'
      ? cashAddr
      : ticker === 'SIGNET'
        ? signetAddr
        : standardAddress

  const tickers = props.tickers?.length
    ? props.tickers
    : props.showSignet
      ? [...defaultTickers, 'SIGNET']
      : defaultTickers

  return (
    <div className="mt-4 flex flex-col items-center text-center">
      {address ? (
        <div className="mt-6 flex items-center justify-center self-center overflow-hidden rounded-lg bg-white dark:bg-gray-900">
          <QRCode
            renderAs="svg"
            includeMargin={true}
            size={200}
            value={address}
          />
        </div>
      ) : (
        <div className="flex h-[200px] w-[200px] items-center justify-center">
          <LoadingIndicator />
        </div>
      )}

      {address && paymail && (
        <>
          <div className="my-2">or</div>
          <CopyToClipboard
            text={showPaymail ? paymail : address}
            onCopy={() =>
              toast.success(`${showPaymail ? 'Paymail' : 'Address'} copied!`)
            }
          >
            <div className="flex justify-center">
              <Tooltip title="Copy">
                <div className="flex cursor-pointer items-center justify-center break-all rounded-lg bg-gray-200 p-2 px-3 text-black dark:bg-gray-700 dark:text-gray-300">
                  {showPaymail ? paymail : address}{' '}
                  <DocumentDuplicateIcon className="ml-2 h-6 w-6 flex-shrink-0 text-gray-700 dark:text-gray-400" />
                </div>
              </Tooltip>
            </div>
          </CopyToClipboard>
        </>
      )}
      <button
        onClick={() => setShowPaymail(!showPaymail)}
        className="btn-link self-center"
      >
        {showPaymail ? 'Hide' : 'Show'} paymail
      </button>

      {tickers.length > 1 && (
        <RadioGroup
          value={ticker}
          onChange={setTicker}
          className="mx-auto mt-6 flex overflow-hidden rounded-lg"
        >
          <RadioGroup.Label className="sr-only">Coin</RadioGroup.Label>
          {tickers.map((symbol) => (
            <RadioGroup.Option
              value={symbol}
              className="flex flex-1 cursor-pointer bg-gray-200 text-center dark:bg-gray-700"
              key={symbol}
            >
              {({ checked, active }) => (
                <div
                  className={`w-full border-2 px-6 py-1 ${cx({
                    'border-theme-purple rounded-lg bg-white dark:text-gray-800':
                      checked || active,
                    'border-transparent': !checked && !active,
                  })}`}
                >
                  {symbol}
                </div>
              )}
            </RadioGroup.Option>
          ))}
        </RadioGroup>
      )}
    </div>
  )
}
