'use client'
import { Wallet } from '../Wallet'
import { useEffect, useState } from 'react'
import Resizer from 'react-image-file-resizer'
import { toast } from 'react-toastify'
import { Button } from './Button'
import { FileDrop } from './FileDrop'
import { Modal } from './Modal'

interface Props {
  open: boolean
  currentProfileImage: string
  onClose: () => void
}

export function EditProfileImageModal(props: Props) {
  const { open, onClose, currentProfileImage } = props

  const [unsavedImage, setUnsavedImage] = useState<any>(null)
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!open) {
      setError(null)
      setUnsavedImage(null)
    }
  }, [open])

  const onDrop = async (droppedFiles: File[]) => {
    setError(null)
    const file = droppedFiles[0]
    try {
      Resizer.imageFileResizer(
        file,
        400,
        400,
        'JPEG',
        100,
        0,
        setUnsavedImage,
        'base64',
      )
    } catch (err: any) {
      setError(err.message)
    }
  }

  const updateImage = async () => {
    try {
      setLoading(true)
      await Wallet.changeImage(unsavedImage)
      toast.success('Profile image updated!')
      onClose()
    } catch (err: any) {
      setError(err.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      title="Edit profile image"
      open={open}
      onClose={onClose}
      showCloseButton={true}
    >
      <img
        alt="profile"
        src={unsavedImage || currentProfileImage}
        className="m-5 mx-auto h-28 w-28 rounded-full"
      />
      <FileDrop
        onDrop={onDrop}
        accept={{ 'image/*': [] }}
        className="mb-4 py-3"
      >
        <div className="input-label m-1 text-center text-xl">
          Drag and drop an image
        </div>
        <div className="text-theme-gray-light text-center text-base">
          <span className="text-theme-purple dark:brightness-125">
            Browse your files
          </span>
        </div>
      </FileDrop>
      <Button
        className="btn-primary mx-auto mt-4 px-10"
        onClick={updateImage}
        loading={loading}
        disabled={!unsavedImage}
      >
        Save
      </Button>
      {!!error && (
        <div className="mt-2 text-center text-lg text-red-500">{error}</div>
      )}
    </Modal>
  )
}
