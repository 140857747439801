import {
  PRODUCT_CATEGORIES,
  PRODUCT_CATEGORY_LABELS,
  capitalizeFirstLetter,
} from '@canonic/lib'
import {
  EditPaymailModal,
  EditProfileImageModal,
  LoadingView,
  Wallet,
} from '@canonic/react'
import { Disclosure } from '@headlessui/react'
import {
  ChevronDownIcon,
  XIcon,
  ChevronRightIcon,
  CollectionIcon,
  ViewListIcon,
  ShoppingBagIcon,
  KeyIcon,
  LinkIcon,
} from '@heroicons/react/outline'
import { Routes } from 'components/AppRoutes'
import usePaymail from 'hooks/usePaymail'
import useProfileImage from 'hooks/useProfileImage'
import { api } from 'lib/api'
import { Suspense, useEffect, useRef, useState } from 'react'
import { NavLink, Outlet, useLocation } from 'react-router-dom'
import cx from 'classnames'
import { useClickAway } from 'react-use'
import FeatureFlag from 'components/FeatureFlag'

type Modal = 'paymail' | 'profile-image' | 'send' | 'receive' | null

export default function ProfileLayout() {
  const { profileImage } = useProfileImage()
  const { paymail } = usePaymail()

  const [modal, setModal] = useState<Modal>(null)
  const [mobileOpen, setMobileOpen] = useState(false)

  const sidebarRef = useRef(null)

  useClickAway(sidebarRef, () => setMobileOpen(false))

  const { pathname } = useLocation()

  useEffect(() => {
    setMobileOpen(false)
  }, [pathname])

  return (
    <div className="-mb-32 flex h-full flex-1 border-t md:-mb-52 dark:border-gray-700/50">
      <aside
        ref={sidebarRef}
        className="overflow-x-hidden bg-gray-200/70 sm:bg-transparent dark:bg-gray-900/70 sm:dark:bg-transparent"
      >
        <div
          className={`absolute inset-y-0 left-0 z-40 h-full w-screen max-w-[18rem] overflow-x-hidden bg-gray-200/70 px-4 backdrop-blur-lg transition-transform sm:relative sm:z-10 sm:w-screen dark:bg-gray-900/70 ${cx(
            {
              '-translate-x-full sm:translate-x-0': !mobileOpen,
            },
          )}`}
        >
          {/* Mobile close button */}
          <button
            type="button"
            className="absolute right-2.5 top-2 block sm:hidden"
            onClick={() => setMobileOpen(false)}
          >
            <XIcon className="h-6 w-6" />
          </button>

          <ul className="mt-6 flex flex-col items-start gap-5 text-lg">
            <StyledLink
              to={Routes.PROFILE.WALLET}
              label="Wallet"
              icon={KeyIcon}
            />
            <Disclosure defaultOpen as={'div'} className="w-full">
              <Disclosure.Button className="flex items-center pb-1 text-base opacity-80">
                <ShoppingBagIcon className="mr-4 h-5 w-5" /> Purchases{' '}
                <ChevronDownIcon className="ui-open:-rotate-180 ml-1 h-5 w-5 transition-transform" />
              </Disclosure.Button>
              <Disclosure.Panel className="mt-1 flex flex-col gap-2 pl-14">
                <OrdersLink />
                <PurchaseCategoryLink
                  category={PRODUCT_CATEGORIES.PHYSICAL.BOOKS}
                />
                <PurchaseCategoryLink
                  category={PRODUCT_CATEGORIES.DIGITAL.MUSIC}
                />
                <PurchaseCategoryLink
                  category={PRODUCT_CATEGORIES.DIGITAL.IMAGES}
                />
                <PurchaseCategoryLink
                  category={PRODUCT_CATEGORIES.DIGITAL.MOVIES}
                />
                <PurchaseCategoryLink
                  category={PRODUCT_CATEGORIES.DIGITAL.EBOOKS}
                />
                <PurchaseCategoryLink
                  category={PRODUCT_CATEGORIES.DIGITAL.AUDIOBOOKS}
                />
                <OrdinalsLink />
              </Disclosure.Panel>
            </Disclosure>
            <Disclosure defaultOpen as={'div'} className="w-full">
              <Disclosure.Button className="flex items-center pb-1 text-base opacity-80">
                <CollectionIcon className="mr-4 h-5 w-5" /> Collectibles{' '}
                <ChevronDownIcon className="ui-open:-rotate-180 ml-1 h-5 w-5 transition-transform" />
              </Disclosure.Button>
              <Disclosure.Panel className="mt-1 flex flex-col gap-2 pl-14">
                <StyledLink
                  to={Routes.PROFILE.COLLECTIBLES}
                  label="Canonic Exclusives"
                />
                <FeatureFlag feature="RUNES">
                  <StyledLink to={Routes.PROFILE.RUNES} label="Runes" />
                </FeatureFlag>
              </Disclosure.Panel>
            </Disclosure>
            <StyledLink
              to={Routes.PROFILE.HISTORY}
              label="Transactions"
              icon={ViewListIcon}
            />
            <StyledLink
              to={Routes.PROFILE.REFERRALS}
              label="Referrals"
              icon={LinkIcon}
            />
          </ul>
        </div>
        <button
          className="block p-2 sm:hidden"
          onClick={() => setMobileOpen(true)}
        >
          <ChevronRightIcon className="dark:text-theme-body-white h-6 w-6 text-black" />
        </button>
      </aside>

      <div className="flex flex-1 overflow-x-hidden px-4 py-6 sm:px-8">
        <Suspense fallback={<LoadingView />}>
          <Outlet />
        </Suspense>
      </div>
      {paymail && (
        <EditPaymailModal
          currentPaymail={paymail}
          open={modal === 'paymail'}
          onClose={() => setModal(null)}
        />
      )}
      {profileImage && (
        <EditProfileImageModal
          currentProfileImage={profileImage}
          open={modal === 'profile-image'}
          onClose={() => setModal(null)}
        />
      )}
    </div>
  )
}

const StyledLink = ({
  to,
  label,
  count,
  icon: Icon,
}: {
  to: string
  label: string
  count?: number
  icon?: React.ComponentType<{ className: string }>
}) => (
  <div className="flex w-full items-center justify-between pb-1 text-base">
    <NavLink to={to}>
      {({ isActive }) => (
        <>
          <span className="flex items-center">
            {Icon && <Icon className="mr-4 h-5 w-5" />}
            <span
              className={` ${
                isActive
                  ? 'text-theme-purple dark:brightness-125'
                  : 'opacity-80'
              }`}
            >
              {label}
            </span>
          </span>
        </>
      )}
    </NavLink>
    {!count ? null : (
      <div className="ml-1.5 rounded bg-gray-300 px-1.5 py-px font-medium text-gray-500 dark:bg-gray-400 dark:text-gray-700">
        {count}
      </div>
    )}
  </div>
)

interface Props {
  category: string
}

function PurchaseCategoryLink(props: Props) {
  const { category } = props

  const { data: products } = api.user.purchasedProducts.useQuery({ category })

  if (!products?.length) return null

  return (
    <StyledLink
      to={Routes.buildPurchasesRoutes(category)}
      label={
        PRODUCT_CATEGORY_LABELS[category.toUpperCase()] ??
        capitalizeFirstLetter(category.toLowerCase())
      }
      count={products.length}
    />
  )
}

function OrdinalsLink() {
  const { data: ordinals } = api.user.ordinalProducts.useQuery()

  if (!ordinals?.length) return null

  return (
    <StyledLink
      to={Routes.PROFILE.PURCHASES.ORDINALS}
      label={`Ordinals`}
      count={ordinals.length}
    />
  )
}

function OrdersLink() {
  const { data: orders } = api.user.orders.useQuery()

  return (
    <StyledLink
      to={Routes.PROFILE.PURCHASES.ORDERS}
      label="All orders"
      count={orders?.length}
    />
  )
}
