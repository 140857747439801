import { Link } from 'react-router-dom'
import logo from '../logo.png'
import { hasAPIToken, api } from 'lib/api'
import useModal from 'hooks/useModal'
import { Routes } from 'components/AppRoutes'
import { ThemeToggle } from '@canonic/react'
import { MailIcon } from '@heroicons/react/outline'
import twitterWhite from 'assets/images/twitter_white.svg'
import twitterBlack from 'assets/images/twitter_black.svg'
import FeedbackButton from './FeedbackButton'

export default function Footer() {
  const { setModal } = useModal()
  const { data: user, isLoading } = api.user.get.useQuery(undefined, {
    enabled: hasAPIToken(),
  })

  return (
    <footer className="dark:text-theme-body-white mt-32 grid w-full place-items-center gap-y-12 bg-gray-200/70 px-6 py-12 text-center text-black md:mt-52 md:grid-cols-3 md:place-items-start md:gap-y-0 md:px-14 md:text-left dark:bg-gray-900/70">
      <Link to={Routes.HOME}>
        <img src={logo} alt="Canonic" className="h-8" />
      </Link>
      <div className="flex gap-12 place-self-center">
        <div className="flex flex-col gap-2">
          <a
            href="mailto:info@canonic.xyz"
            target="_blank"
            rel="noreferrer"
            className="flex items-center"
          >
            <MailIcon className="mr-2 h-5 w-5 text-black dark:text-white" />{' '}
            Email
          </a>
          <a
            href="https://twitter.com/canonicxyz"
            target="_blank"
            rel="noreferrer"
            className="flex items-center"
          >
            <img
              src={twitterWhite}
              alt="Twitter"
              height={20}
              width={20}
              className="mr-2 hidden dark:inline"
            />
            <img
              src={twitterBlack}
              alt="Twitter"
              height={20}
              width={20}
              className="mr-2 dark:hidden"
            />{' '}
            Twitter
          </a>
          <FeedbackButton />
        </div>

        <div className="flex flex-col items-start gap-2">
          <Link to={Routes.FAQ}>FAQ</Link>
          <Link to={Routes.PRIVACY_POLICY}>Privacy policy</Link>
          <Link to={Routes.TERMS_OF_USE}>Terms of use</Link>
        </div>
      </div>

      <div className="flex w-full items-center justify-between gap-4 md:ml-auto md:w-auto md:justify-start">
        <div className="w-20 md:hidden" />
        {!isLoading && !user && (
          <div className="flex w-full flex-row justify-center gap-4 md:w-auto md:justify-start">
            <button onClick={() => setModal('login')} className="btn-white">
              Login
            </button>
            <button onClick={() => setModal('signup')} className="btn-primary">
              Sign up
            </button>
          </div>
        )}
        <ThemeToggle />
      </div>
    </footer>
  )
}
