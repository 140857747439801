'use client'
import { BASE_PATHS } from '@canonic/lib'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Button } from './Button'
import { Modal } from './Modal'
import { ToggleButton } from './ToggleButton'
import { Tooltip } from './Tooltip'
import { Input } from './FormFields'
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import * as atfinder from '@kevinejohn/atfinder'
import { LoadingIndicator } from './LoadingIndicator/LoadingIndicator'
import { Wallet } from '../Wallet'

interface Props {
  screen: 'login' | 'signup'
  /** Called after a user is sucessfully signed in */
  onSuccess: (
    user: NonNullable<Awaited<ReturnType<typeof Wallet.getUser>>>,
  ) => Promise<void> | void
  signupLink?: React.ElementType<any>
  loginLink?: React.ElementType<any>
  formSubmitAnalyticsEvent?: string
  hideHeading?: boolean
  authorizerForPaymail?: string
  /** Multi-Wallet Auth */
  mwa?: {
    requesterPaymail: string
    email: string
  } | null
}

/** Re-usable component to handle login and signup */
export function Authentication(props: Props) {
  const {
    screen = 'login',
    onSuccess,
    signupLink: SignupLink,
    loginLink: LoginLink,
    formSubmitAnalyticsEvent: formSubmitAnalyticsEvent,
    hideHeading,
    mwa,
  } = props

  const [email, setEmail] = useState(mwa?.email ?? '')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [loading, setLoading] = useState(false)
  const [seed, setSeed] = useState('')
  const [importingWallet, setImportingWallet] = useState(false)
  /** A key-value pair of found xpubs and their base path */
  const [foundBasePaths, setFoundBasePaths] = useState<Record<string, string>>()
  const [authorizerForUser, setAuthorizerForUser] = useState<{
    paymail: string
    image: string
  } | null>(null)

  const isSignup = screen === 'signup'

  useEffect(() => {
    if (!importingWallet) {
      setSeed('')
      setFoundBasePaths(undefined)
    }
  }, [importingWallet])

  useEffect(() => {
    toast.dismiss()
    setSeed('')
    setImportingWallet(false)
    setFoundBasePaths(undefined)
  }, [screen])

  useEffect(() => {
    if (mwa) {
      const fetchAuthorizerUser = async () => {
        try {
          const { avatar, paymail_pretty } = await atfinder.getNameAndPhotoURL(
            mwa.requesterPaymail,
          )
          setAuthorizerForUser({ paymail: paymail_pretty, image: avatar })
        } catch (err) {
          console.error(`Error fetching authorizer user:`, err)
        }
      }
      fetchAuthorizerUser()
    } else {
      setAuthorizerForUser(null)
    }
  }, [mwa])

  const signUp = async (basePath?: string) => {
    if (password !== passwordConfirmation) {
      throw Error(`Passwords don't match`)
    }

    // Determine the correct base path if one wasn't provided
    if (importingWallet && !basePath) {
      if (!Wallet.validSeed(seed)) throw Error(`Invalid seed words`)

      // Xpubs
      const canonicXpub = Wallet.getXpub(seed).xpub
      const mbXpub = Wallet.getXpub(seed, BASE_PATHS.MONEYBUTTON).xpub
      const relayxXpub = Wallet.getXpub(seed, BASE_PATHS.RELAYX).xpub

      const { xpubs, xpubs_matched } = await Wallet.validateXpubs([
        canonicXpub,
        mbXpub,
        relayxXpub,
      ])

      if (!xpubs_matched)
        throw Error('Could not find matching wallet from seed words.')

      if (xpubs[canonicXpub]) xpubs[canonicXpub] = BASE_PATHS.CANONIC
      if (xpubs[mbXpub]) xpubs[mbXpub] = BASE_PATHS.MONEYBUTTON
      if (xpubs[relayxXpub]) xpubs[relayxXpub] = BASE_PATHS.RELAYX

      // If more than one found, we need to let the user choose which wallet to import
      if (xpubs_matched > 1) {
        setFoundBasePaths(xpubs)
        throw Error('Multiple wallets found. Choose your wallet.')
      }

      // Select the base path if there's only one
      basePath = xpubs[canonicXpub] || xpubs[mbXpub] || xpubs[relayxXpub]
    }

    try {
      await Wallet.login({ email, password })
      Wallet.logout()

      throw Error('User already exists. Please log in.')
    } catch (err) {
      const wallet =
        basePath === BASE_PATHS.CANONIC
          ? 'canonic'
          : basePath === BASE_PATHS.MONEYBUTTON
            ? 'moneybutton'
            : basePath === BASE_PATHS.RELAYX
              ? 'relayx'
              : undefined

      await Wallet.createAccount({
        email: mwa?.email || email,
        password,
        seed,
        wallet,
        authorizerFor: mwa?.requesterPaymail,
      })
    }
  }

  const login = async () => {
    await Wallet.login({ email, password })
  }

  const onSubmit = async (e: React.SyntheticEvent, basePath?: string) => {
    e.preventDefault()
    toast.dismiss()
    setLoading(true)

    try {
      Wallet.logout()
      if (isSignup) {
        await signUp(basePath)
      } else {
        await login()
      }

      const user = await Wallet.getUser()
      if (Wallet.isLoggedIn() && user) {
        await onSuccess(user)
      } else {
        throw Error('Error logging in.')
      }
    } catch (err: any) {
      toast.error(<div data-testid="authError">{err.message}</div>, {
        autoClose: false,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="flex w-full max-w-screen-sm flex-col items-center px-3 text-center">
      {!hideHeading && (
        <div className="mt-4 text-center text-base">
          {isSignup ? 'Create a' : 'Use your'} Canonic wallet to make purchases
          with Bitcoin.
        </div>
      )}
      {isSignup ? (
        mwa && !authorizerForUser ? (
          <LoadingIndicator className="my-6" />
        ) : (
          authorizerForUser && (
            <div
              className={
                'dark:text-theme-body-white my-6 flex items-start space-x-1.5 rounded-lg border-2 border-l-8 border-yellow-500 px-3 py-3 text-xl text-black'
              }
            >
              <ExclamationCircleIcon className="h-7 w-7 flex-shrink-0 text-yellow-500" />
              <div className="space-y-1 text-left">
                <div className="font-bold">Creating Authorized Wallet</div>
                <div className="text-base">
                  <p>
                    You are creating an authorized wallet. Any payments made
                    from the below wallet will require approval from you:
                  </p>
                  <div className="dark:text-theme-body-white my-2 flex items-center gap-2 font-bold text-black">
                    <img
                      src={authorizerForUser.image}
                      alt={authorizerForUser.paymail}
                      className="h-7 w-7 rounded-full"
                    />
                    {authorizerForUser.paymail}
                  </div>
                  <p>
                    Please verify the source of this link before continuing.
                  </p>
                </div>
              </div>
            </div>
          )
        )
      ) : null}

      <form
        onSubmit={onSubmit}
        data-umami-event={formSubmitAnalyticsEvent}
        className={`mt-4 flex w-full flex-col items-center`}
      >
        <Input
          type="email"
          name="email"
          placeholder="Email"
          disabled={!!(isSignup && mwa)}
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <Input
          className="mt-2"
          type="password"
          name="password"
          placeholder="Password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
        />
        {isSignup && (
          <>
            <Input
              className="mt-2"
              type="password"
              name="confirmPassword"
              placeholder="Confirm password"
              onChange={(e) => setPasswordConfirmation(e.target.value)}
            />
            <div className="ml-auto mt-4 flex flex-row items-center">
              <span className="mr-2">Import wallet</span>

              <ToggleButton
                enabled={importingWallet}
                setEnabled={setImportingWallet}
                srLabel="Enable wallet import"
              />
            </div>
            {importingWallet && (
              <>
                <Input
                  className="mt-2"
                  type="text"
                  name="seed"
                  placeholder="12 word seed"
                  onChange={(e) => setSeed(e.target.value)}
                />
                <Modal
                  onClose={() => {
                    setFoundBasePaths(undefined)
                    toast.dismiss()
                  }}
                  preventOutsideClick={true}
                  open={!!foundBasePaths}
                  title="Select wallet"
                >
                  <div className="py-4 text-center">
                    <p>
                      Multiple wallets found that match the provided 12 word
                      seed. <br /> Select your wallet to continue:
                    </p>
                    <div className="mt-2 flex w-full space-x-4">
                      {Object.entries(foundBasePaths || {}).map(
                        ([xpub, base]) => (
                          <Tooltip title={`Base path: ${base}`} key={xpub}>
                            <button
                              onClick={(e) => {
                                onSubmit(e, base)
                              }}
                              className="hover:border-theme-purple dark:hover:border-theme-purple flex-1 rounded-lg border bg-white py-2 text-base dark:border-transparent dark:bg-gray-700"
                            >
                              {BASE_PATHS.CANONIC === base && 'Canonic'}
                              {BASE_PATHS.MONEYBUTTON === base && 'MoneyButton'}
                              {BASE_PATHS.RELAYX === base && 'RelayX'}
                            </button>
                          </Tooltip>
                        ),
                      )}
                    </div>
                  </div>
                </Modal>
              </>
            )}
          </>
        )}

        <div className="mt-6 flex flex-col space-y-1">
          {isSignup ? (
            <>
              <Button
                loading={loading}
                disabled={
                  !email ||
                  !password ||
                  !passwordConfirmation ||
                  (importingWallet && !seed)
                }
                className="btn-primary mb-1"
                type="submit"
              >
                Sign up
              </Button>

              {LoginLink && <LoginLink />}
            </>
          ) : (
            <>
              <Button
                loading={loading}
                disabled={!email || !password}
                className="btn-primary mb-1"
                type="submit"
              >
                Login
              </Button>
              {SignupLink && <SignupLink />}
            </>
          )}
        </div>
      </form>
    </div>
  )
}
