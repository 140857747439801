import { FlagIcon } from '@heroicons/react/solid'
import { getClient, BrowserClient, Feedback } from '@sentry/react'
import { twMerge } from 'tailwind-merge'

interface Props {
  className?: string
  buttonLabel?: string
}

export default function FeedbackButton(props: Props) {
  const client = getClient<BrowserClient>()
  const feedback = client?.getIntegration(Feedback)

  return (
    <button
      onClick={() =>
        feedback
          ? feedback.openDialog()
          : alert('feedback integration not enabled (are you testing locally?)')
      }
      className={twMerge(`flex items-center`, props.className)}
    >
      <FlagIcon className="dark:text-theme-body-white mr-2 h-5 w-5 text-black" />{' '}
      {props.buttonLabel ?? 'Report a bug'}
    </button>
  )
}
