'use client'
import {
  arrow,
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  Placement,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react'
import { Transition } from '@headlessui/react'
import { cloneElement, useRef, useState } from 'react'
import cx from 'classnames'

interface Props {
  children: JSX.Element
  placement?: Placement
  /** Defaults to `true` (best for mobile support). */
  clickEnabled?: boolean
  /** Defaults to `true`. */
  hoverEnabled?: boolean
  title?: string | JSX.Element
  /** Defaults to `dark`. */
  theme?: 'light' | 'dark'
}

export function Tooltip(props: Props) {
  const {
    children,
    title,
    placement = 'top',
    hoverEnabled = true,
    clickEnabled = true,
    theme = 'dark',
  } = props
  const [open, setOpen] = useState(false)
  const arrowRef = useRef(null)

  const { x, y, refs, strategy, context, middlewareData, floatingStyles } =
    useFloating({
      placement,
      open,
      onOpenChange: setOpen,
      middleware: [
        offset(8),
        flip({ fallbackAxisSideDirection: 'start' }),
        shift({ padding: 8 }),
        arrow({ element: arrowRef }),
      ],
      whileElementsMounted: autoUpdate,
    })

  const hover = useHover(context, { move: false, enabled: hoverEnabled })
  const focus = useFocus(context)
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: 'tooltip' })
  const click = useClick(context, { enabled: clickEnabled })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
    click,
  ])

  const arrowX = middlewareData.arrow?.x
  const arrowY = middlewareData.arrow?.y
  const staticSide =
    {
      top: 'bottom',
      right: 'left',
      bottom: 'top',
      left: 'right',
    }[placement.split('-')[0]] || ''

  const light = theme === 'light'
  return (
    <>
      {cloneElement(
        children,
        getReferenceProps({ ref: refs.setReference, ...children.props }),
      )}
      {title && (
        <FloatingPortal>
          <Transition
            show={open}
            enter="transition-opacity"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity"
            leaveFrom="transition-100"
            leaveTo="opacity-0"
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps({
              className: `rounded-lg z-[9999999] break-normal ${cx({
                'bg-white dark:text-black': light,
                'text-theme-body-white bg-gray-800 dark:bg-gray-700': !light,
              })} max-w-xs font-normal w-max px-3 py-2 text-sm shadow-lg dark:shadow-gray-900`,
            })}
          >
            <div
              ref={arrowRef}
              className={`absolute z-10 h-3 w-3 origin-center rotate-45 ${cx({
                'bg-gray-800 dark:bg-gray-700': !light,
                'bg-white': light,
              })}`}
              style={{
                left: arrowX !== null ? `${arrowX}px` : '',
                top: arrowY !== null ? `${arrowY}px` : '',
                right: '',
                bottom: '',
                [staticSide]: '-.275rem',
              }}
            />
            {title}
          </Transition>
        </FloatingPortal>
      )}
    </>
  )
}
