import { bsv } from '@canonic/lib'
import { atom, useAtom } from 'jotai'
import { LOCAL_STORAGE } from 'lib/constants'
import { useEffect } from 'react'
import { useLocalStorage } from 'react-use'

const guestPubkeyAtom = atom<string | null>(null)

const getNewGuestPubKey = () => {
  const pubKey = bsv.PubKey.fromPrivKey(bsv.PrivKey.fromRandom()).toString()

  return pubKey
}

export function useGuestCart() {
  const [guestPubKey, setGuestPubkey] = useAtom(guestPubkeyAtom)

  const [lsValue, setLs, removeLs] = useLocalStorage<string | null>(
    LOCAL_STORAGE.GUEST_CART_PUBKEY,
  )

  useEffect(() => {
    if (lsValue) {
      setGuestPubkey(lsValue)
    } else {
      setGuestPubkey(null)
    }
  }, [lsValue])

  return {
    guestPubKey,
    setGuestPubkey: setLs,
    removeGuestPubkey: removeLs,
    getNewGuestPubKey,
  }
}
