import { formatCurrency } from '@canonic/lib'
import { AllWalletUtxos, CombinedWalletHistory, Wallet } from '@canonic/react'
import { atom, useAtom } from 'jotai'
import { Env } from 'lib/env'

const balanceAtom = atom<string | null>(null)
const historyAtom = atom<CombinedWalletHistory | null>(null)
const allUtxosAtom = atom<AllWalletUtxos | null>(null)
const satsAtom = atom<number | null>(null)

export default function useBalance() {
  const [balance, setBalance] = useAtom(balanceAtom)
  const [history, setHistory] = useAtom(historyAtom)
  const [allUtxos, setAllUtxos] = useAtom(allUtxosAtom)
  const [sats, setSats] = useAtom(satsAtom)

  const refreshBalance = async () => {
    if (!Wallet.isLoggedIn()) return
    try {
      const { usd, sats } = Wallet.getBalance()

      setBalance(formatCurrency(usd))
      setSats(sats)
      setHistory(Wallet.getCombinedHistory({ showSignet: Env.SIGNET_ENABLED }))
      setAllUtxos(Wallet.getAllUtxos())
    } catch (err) {
      console.error(`useBalance error:`, err)
    }
  }

  return {
    balance,
    history,
    allUtxos,
    sats,
    refreshBalance,
  }
}
