import { FEATURE_FLAGS } from 'lib/constants'
import { Env } from 'lib/env'
import { PostHogFeature } from 'posthog-js/react'
import React from 'react'

interface Props {
  feature: keyof typeof FEATURE_FLAGS
  children: React.ReactNode
}

export default function FeatureFlag(props: Props) {
  if (!Env.PROD) {
    return props.children
  }

  return (
    <PostHogFeature flag={FEATURE_FLAGS[props.feature]} match={true}>
      {props.children}
    </PostHogFeature>
  )
}
