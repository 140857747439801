import { Modal } from '@canonic/react'
import { ReceiveBitcoin } from '@canonic/react/src/components/ReceiveBitcoin'
import { CheckCircleIcon } from '@heroicons/react/outline'
import useBalance from 'hooks/useBalance'
import useModal from 'hooks/useModal'
import usePaymail from 'hooks/usePaymail'
import { Routes } from 'components/AppRoutes'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import { PRODUCT_CATEGORIES } from '@canonic/lib'

export default function WelcomeModal() {
  const { modal, setModal } = useModal()
  const { paymail } = usePaymail()
  const { sats } = useBalance()

  const [animatedCard, setAnimatedCard] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimatedCard((c) => (c + 1) % 3)
    }, 3000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <Modal
      open={modal.name === 'welcome'}
      onClose={() => setModal(null)}
      title="Welcome"
      size="2xl"
    >
      <p className="mt-8 text-center text-lg">
        You'll need to fund your wallet before publishing and purchasing on
        Canonic. Send BTC, BCH, or BSV to your wallet below.
      </p>
      <p className="b-2 mt-8 block text-center text-lg">
        After funding your wallet, start using Canonic to publish, purchase and
        trade books using Bitcoin.
      </p>
      {!sats ? (
        <ReceiveBitcoin paymail={paymail} />
      ) : (
        <div className="dark:text-theme-body-white mt-12 flex items-center justify-center rounded-lg border-2 border-green-400 px-3 py-2 text-2xl text-black">
          Wallet Funded
          <CheckCircleIcon className="ml-2 h-9 w-9 text-green-400" />
        </div>
      )}

      <div className="mt-20 grid w-full grid-cols-1 gap-4 sm:grid-cols-3">
        <Link
          to={Routes.ADMIN.NEW_PRODUCT}
          onClick={() => setModal(null)}
          className={`dark:text-theme-body-white hover:border-theme-purple w-full rounded-lg border-2 bg-white px-2 py-6 text-center text-black shadow transition-colors duration-500 dark:bg-gray-700/80 dark:shadow-none ${cx(
            {
              'border-theme-purple': animatedCard === 0,
              'border-transparent': animatedCard !== 0,
            },
          )}`}
        >
          <div className="text-3xl">Publish</div>
          <p className="mt-2 px-4">Publish with Bitcoin, on Bitcoin.</p>
        </Link>
        <Link
          to={Routes.buildExploreRoute(PRODUCT_CATEGORIES.DIGITAL.EBOOKS)}
          onClick={() => setModal(null)}
          className={`dark:text-theme-body-white hover:border-theme-purple w-full rounded-lg border-2 bg-white px-2 py-6 text-center text-black shadow transition-colors duration-500 dark:bg-gray-700/80 dark:shadow-none ${cx(
            {
              'border-theme-purple': animatedCard === 1,
              'border-transparent': animatedCard !== 1,
            },
          )}`}
        >
          <div className="text-3xl">Read</div>
          <p className="mt-2 px-4">Explore and purchase books.</p>
        </Link>
        <Link
          to={Routes.COLLECTIBLES}
          onClick={() => setModal(null)}
          className={`dark:text-theme-body-white hover:border-theme-purple w-full rounded-lg border-2 bg-white px-2 py-6 text-center text-black shadow transition-colors duration-500 dark:bg-gray-700/80 dark:shadow-none ${cx(
            {
              'border-theme-purple': animatedCard === 2,
              'border-transparent': animatedCard !== 2,
            },
          )}`}
        >
          <div className="text-3xl">Trade</div>
          <p className="mt-2 px-4">Collectibles only on Canonic.</p>
        </Link>
      </div>
    </Modal>
  )
}
