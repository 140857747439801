import { MoneyButtonClient } from '@moneybutton/api-client'
import { Routes } from '../components/AppRoutes'
import { Env } from './env'

const OAUTH_IDENTIFIER = !Env.DEV
  ? '9e67cb7fd7a73f187b1b303049d5c8f7'
  : 'ea8813e659810b80069c9612a099fa95'

const CLIENT_IDENTIFIER = !Env.DEV
  ? '143bf4e29c777e9d4744919c1e11a9a9'
  : '53694fd9840e5fe81079f11076af1a92'

const client = new MoneyButtonClient(OAUTH_IDENTIFIER)

const savePermissionToken = (token: string) => {
  localStorage.setItem('mb_permission_token', token)
}

const getPermissionToken = () => {
  return localStorage.getItem('mb_permission_token')
}

/// Get the IMB client, which could be `undefined` because the MoneyButton client might not be loaded yet
const getImb = () =>
  window.moneyButton &&
  new window.moneyButton.IMB({
    clientIdentifier: CLIENT_IDENTIFIER,
    permission: getPermissionToken(),
    onNewPermissionGranted: savePermissionToken,
  })

const authenticate = () => {
  client.requestAuthorization(
    'auth.user_identity:read users.profiles:read users.profiles.email:read users.balance:read',
    !Env.DEV
      ? `https://canonic.xyz${Routes.PROFILE.RESTORE_PURCHASES}`
      : `http://localhost:3000${Routes.PROFILE.RESTORE_PURCHASES}`,
  )
}

const decrypt = async (hexString: string) => {
  const imb = getImb()
  if (!imb) throw Error('Missing MoneyButton library')

  const { cryptoOperations } = await imb.swipe({
    cryptoOperations: [
      {
        name: 'key',
        method: 'decrypt',
        data: hexString,
        dataEncoding: 'hex',
        key: 'identity',
        valueEncoding: 'utf8',
      },
    ],
  })

  if (!cryptoOperations[0]) throw Error('Error decrypting with MoneyButton')

  return cryptoOperations[0].value
}

const sign = async (message: string) => {
  const imb = getImb()
  if (!imb) throw Error('Missing MoneyButton library')

  const { cryptoOperations } = await imb.swipe({
    cryptoOperations: [
      {
        name: 'signature',
        method: 'sign',
        data: message,
        key: 'identity',
      },
    ],
  })

  if (!cryptoOperations[0]) throw Error('Error signing with MoneyButton')

  return cryptoOperations[0].value
}

const moneybutton = {
  client,
  authenticate,
  logout: () => client.logOut(),
  decrypt,
  sign,
}

export default moneybutton
