import { LoadingIndicator, LoadingView } from '@canonic/react'
import { Routes } from 'components/AppRoutes'
import { api, getImageUrl } from 'lib/api'
import { Link, NavLink, Outlet, useLocation } from 'react-router-dom'
import React, { useState, useRef, useEffect, Suspense } from 'react'
import { useClickAway } from 'react-use'
import {
  BellIcon,
  ChevronRightIcon,
  CogIcon,
  KeyIcon,
  PlusIcon,
  TableIcon,
  UserGroupIcon,
  ViewGridIcon,
  XIcon,
} from '@heroicons/react/outline'
import cx from 'classnames'
import logo from '../../../logo.png'
import { useNotifications } from 'hooks/hooks'
import { useAdminLayoutPrimaryUI } from 'hooks/useAdminLayoutPrimaryUI'

export const getDefaultAdminLayoutPrimaryUI = () => (
  <Link
    to={Routes.ADMIN.NEW_PRODUCT}
    className="btn-primary hidden items-center sm:flex"
    data-testid="newProductButton"
  >
    <PlusIcon className="mr-1 h-5 w-5" /> New Product
  </Link>
)

export default function AdminLayout() {
  const { data: store, isLoading } = api.admin.store.useQuery()
  const { data: orders } = api.admin.orders.useQuery()
  const { data: notifications } = api.user.notifications.useQuery()
  const unreadNotifications = notifications?.filter((n) => !n.seen).length

  const [, setNotficationsOpen] = useNotifications()

  const unfulfilledOrderCount = orders?.filter(
    ({ fulfilled_at }) => !fulfilled_at,
  ).length

  const [mobileOpen, setMobileOpen] = useState(false)
  const { ui: primaryUI } = useAdminLayoutPrimaryUI()

  const sidebarRef = useRef(null)

  useClickAway(sidebarRef, () => setMobileOpen(false))

  const { pathname } = useLocation()

  useEffect(() => {
    setMobileOpen(false)
  }, [pathname])

  return (
    <div className="flex min-h-screen flex-col">
      <nav className="flex items-center justify-between px-4 py-4">
        <Link to="/" className="flex-shrink-0">
          <img src={logo} alt="Canonic" className="w-44" />
        </Link>
        <div className="flex items-center">
          {primaryUI ?? getDefaultAdminLayoutPrimaryUI()}
          <button
            onClick={() => setNotficationsOpen(true)}
            className="relative ml-4 block h-6 w-6 focus:outline-none"
            data-testid="mobileMenuButton"
          >
            {!!unreadNotifications && (
              <div className="bg-theme-purple absolute right-0 top-0 flex h-3.5 w-3.5 items-center justify-center rounded-full text-xs text-white">
                {unreadNotifications}
              </div>
            )}
            <BellIcon className="h-5 w-5" />
          </button>
        </div>
      </nav>
      <div className="flex flex-1 border-t dark:border-gray-700">
        <aside
          ref={sidebarRef}
          className="flex flex-col bg-gray-200/70 sm:bg-transparent dark:bg-gray-900/70 sm:dark:bg-transparent"
        >
          <div
            className={`absolute inset-y-0 left-0 z-40 flex w-screen max-w-[15rem] flex-1 flex-col bg-gray-200/70 px-4 backdrop-blur-lg transition-transform sm:relative sm:z-10 sm:w-screen dark:bg-gray-900/70 ${cx(
              {
                '-translate-x-full sm:translate-x-0': !mobileOpen,
              },
            )}`}
          >
            <button
              type="button"
              className="absolute right-2.5 top-2 block sm:hidden"
              onClick={() => setMobileOpen(false)}
            >
              <XIcon className="h-6 w-6" />
            </button>
            <div
              className={`flex flex-col items-center gap-3 ${cx({
                'py-6': store,
                'mt-4': !store,
              })}`}
            >
              {isLoading ? (
                <>
                  <div className="h-20 w-20 flex-shrink-0 animate-pulse rounded bg-gray-200/75 dark:bg-gray-600/75" />
                  <div className="mt-2 w-full rounded bg-gray-200/75 text-xl text-transparent dark:bg-gray-600/75">
                    Loading...
                  </div>
                </>
              ) : store ? (
                <>
                  <img
                    src={getImageUrl(store.image)}
                    className="lazyload h-20 w-20 rounded object-cover"
                  />
                  <Link to={Routes.ADMIN.SETTINGS}>
                    <h2 className="dark:text-theme-body-white mt-2 truncate text-xl leading-none text-black">
                      {store.name}
                    </h2>
                  </Link>
                </>
              ) : null}
            </div>
            <ul className="mt-3 flex flex-1 flex-col items-center gap-5 text-center text-lg">
              <StyledLink
                to={Routes.ADMIN.PRODUCTS}
                label="Products"
                icon={ViewGridIcon}
              />
              <StyledLink
                to={Routes.ADMIN.ORDERS}
                label="Orders"
                count={unfulfilledOrderCount}
                icon={TableIcon}
              />
              <StyledLink
                to={Routes.ADMIN.CUSTOMERS}
                label="Customers"
                icon={UserGroupIcon}
              />
              <StyledLink
                to={Routes.ADMIN.SETTINGS}
                label="Store Details"
                icon={CogIcon}
              />
              <StyledLink
                to={Routes.PROFILE.WALLET}
                label="Wallet"
                icon={KeyIcon}
              />
            </ul>
          </div>
          <button
            className="block p-2 sm:hidden"
            onClick={() => setMobileOpen(true)}
          >
            <ChevronRightIcon className="dark:text-theme-body-white h-6 w-6 text-black" />
          </button>
        </aside>

        <div className="max-w-screen-lg flex-1 overflow-x-hidden px-4 py-6 sm:px-8">
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            <Suspense fallback={<LoadingView />}>
              <Outlet />
            </Suspense>
          )}
        </div>
      </div>
    </div>
  )
}

const StyledLink = ({
  to,
  label,
  count,
  icon: Icon,
}: {
  to: string
  label: string
  count?: number
  icon?: React.ComponentType<{ className: string }>
}) => (
  <div className="flex w-full items-center justify-between pb-1 text-base">
    <NavLink to={to}>
      {({ isActive }) => (
        <>
          <span className="flex items-center">
            {Icon && <Icon className="mr-4 h-5 w-5" />}
            <span
              className={` ${
                isActive
                  ? 'text-theme-purple dark:brightness-125'
                  : 'opacity-80'
              }`}
            >
              {label}
            </span>
          </span>
        </>
      )}
    </NavLink>
    {!count ? null : (
      <div className="ml-1.5 rounded bg-gray-300 px-1.5 py-px font-medium text-gray-500 dark:bg-gray-400 dark:text-gray-700">
        {count}
      </div>
    )}
  </div>
)
