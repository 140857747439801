import { HTMLProps } from 'react'

type Props = HTMLProps<HTMLAnchorElement>

export default function ExternalLink(props: Props) {
  const { children, target = '_blank' } = props
  return (
    <a {...props} target={target} rel="noopener noreferrer">
      {children}
    </a>
  )
}
