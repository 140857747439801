import { api } from 'lib/api'
import { timeAgo } from 'lib/strings'
import { format } from 'date-fns'
import { useUserSse } from 'hooks/useSse'
import { toast } from 'react-toastify'
import { ViewOnChain } from '@canonic/react'
import SideDrawer from './SideDrawer'
import { useNotifications } from 'hooks/hooks'

export default function Notifications() {
  const [notificationsOpen, setNotificationsOpen] = useNotifications()
  const { data: user } = api.user.get.useQuery()
  const { data: notifications, refetch } = api.user.notifications.useQuery()

  const clearNotificationsMutation = api.user.clearNotifications.useMutation({
    onSuccess: () => refetch(),
  })

  const close = async () => {
    setNotificationsOpen(false)
    await clearNotificationsMutation.mutateAsync()
  }

  useUserSse(user?.keys.id.paymail, () => {
    refetch()
    toast.info(
      <div>
        <div className="dark:text-theme-body-white text-xl font-medium text-black">
          New Notification
        </div>
        <div>Check your notifications for more details.</div>
      </div>,
      { autoClose: 8000 },
    )
  })

  return (
    <SideDrawer open={notificationsOpen} onClose={close}>
      <div className="px-6 py-4">
        <div className="dark:text-theme-body-white text-2.5xl pb-1 text-black">
          Notifications
        </div>
        {notifications?.map((notification) => (
          <div
            key={notification.date}
            className="flex flex-col border-b py-4 dark:border-b-gray-500"
          >
            {!notification.seen && (
              <div className="bg-theme-purple mb-2 self-start rounded-lg px-1 text-[.65rem] uppercase text-white">
                New
              </div>
            )}
            <p className="dark:text-theme-body-white -mt-1 mb-1 leading-tight text-black">
              {notification.text}
            </p>
            <div className="flex items-center text-xs">
              {timeAgo(notification.date * 1000)} -{' '}
              {format(notification.date * 1000, 'MMMM do, yyyy')}
              {notification.txid && (
                <div className="ml-2">
                  <ViewOnChain txid={notification.txid} />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </SideDrawer>
  )
}
