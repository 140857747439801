import { lazy, useEffect, useState } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { BrowserRouter as Router, useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/react'

// Components
import LoginModal from 'components/Wallet/LoginModal'
import Notifications from 'components/Notifications'

// Config/Lib
import { AppRoutes } from './components/AppRoutes'
import { getAPIToken, hasAPIToken, api } from './lib/api'
import CatastrophicErrorBoundary from 'components/CatastrophicErrorBoundary'
import useProfileImage from 'hooks/useProfileImage'
import usePaymail from 'hooks/usePaymail'
import useBalance from 'hooks/useBalance'
import ScrollToTop from 'components/ScrollToTop'
import { Helmet } from 'react-helmet'
import { Toast } from '@canonic/react'
import { httpBatchLink, httpLink, splitLink } from '@trpc/client'
import WelcomeModal from 'components/WelcomeModal'
import { useOffline } from 'hooks/useOffline'
import Offline from 'pages/Offline'
import useRunes from 'hooks/useRunes'
import { useWalletEvents } from 'hooks/useWalletEvents'
import { AnalyticsProvider, useAnalytics } from 'lib/analytics'
import { Env } from 'lib/env'

const AudioPlayer = lazy(
  () => import('components/AudioPlayer/AudioPlayerController'),
)

// const releaseDate = new Date('2021-03-17T17:00:00.000Z')

function App() {
  const { refreshProfileImage } = useProfileImage()
  const { refreshPaymail } = usePaymail()
  const { refreshBalance } = useBalance()
  const { refreshRunes } = useRunes()

  useWalletEvents()

  const [didSetAnalyticsUser, setDidSetAnalyticsUser] = useState(false)
  const analytics = useAnalytics()

  const offline = useOffline()

  const { data: user } = api.user.get.useQuery(undefined, {
    enabled: hasAPIToken(),
  })

  const location = useLocation()

  useEffect(() => {
    analytics.trackPageView()
  }, [location])

  useEffect(() => {
    if (user) {
      refreshProfileImage()
      refreshPaymail()
      refreshBalance()
      refreshRunes()

      if (!didSetAnalyticsUser) {
        setDidSetAnalyticsUser(true)

        analytics.identifyUser(user.keys.id.pubkey, user)

        Sentry.setUser({
          email: user.email,
          id: user.keys.id.pubkey,
          ip_address: '{{auto}}',
        })
      }
    }

    if (!user && didSetAnalyticsUser) {
      setDidSetAnalyticsUser(false)
      Sentry.setUser(null)
      analytics.clearUser()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        registration.unregister()

        if (caches) {
          // Service worker cache should be cleared with caches.delete()
          caches.keys().then(async (names) => {
            await Promise.all(names.map((name) => caches.delete(name)))
          })
        }
      })
    }
  }, [])

  return (
    <>
      <Helmet title="Canonic" />
      <ScrollToTop />
      {offline ? (
        <Offline />
      ) : (
        <>
          <AppRoutes />
          <AudioPlayer />
          {user && <Notifications />}
          <LoginModal />
          <WelcomeModal />
        </>
      )}
    </>
  )
}

const AppWrapper = () => {
  const [queryClient] = useState(() => new QueryClient())

  const [trpcClient] = useState(() => {
    const url = Env.DEV ? 'http://localhost:4000/api/trpc' : '/api/trpc'

    // This needs to be a function otherwise the token will be stale
    const headers = () => {
      const token = getAPIToken()
      const headers = !token ? {} : { authorization: `bearer ${getAPIToken()}` }

      return headers
    }

    return api.createClient({
      links: [
        splitLink({
          condition(op) {
            return op.context.skipBatch === true
          },
          true: httpLink({
            url,
            headers,
          }),
          false: httpBatchLink({
            url,
            headers,
          }),
        }),
      ],
    })
  })

  return (
    <CatastrophicErrorBoundary>
      <AnalyticsProvider>
        <api.Provider client={trpcClient} queryClient={queryClient}>
          <QueryClientProvider client={queryClient}>
            <Router>
              <App />
              <Toast />
            </Router>
          </QueryClientProvider>
        </api.Provider>
      </AnalyticsProvider>
    </CatastrophicErrorBoundary>
  )
}

export default AppWrapper
