import { IUser } from '@canonic/lib'
import { Env } from 'lib/env'
import { Properties } from 'posthog-js'
import { PostHogProvider, usePostHog } from 'posthog-js/react'
import { ReactNode } from 'react'

export const AnalyticsProvider = ({ children }: { children: ReactNode }) => (
  <PostHogProvider
    apiKey={Env.PROD ? import.meta.env.VITE_POSTHOG_KEY : undefined}
    options={{
      api_host: import.meta.env.VITE_POSTHOG_HOST,
      opt_out_capturing_by_default: !Env.PROD,
    }}
  >
    {children}
  </PostHogProvider>
)

const ANALYTICS_IDS = {
  BUY_NOW_BUTTON: 'buy_now_button',
  ADD_TO_CART_BUTTON: 'add_to_cart_button',
}

export const analyticsTag = (id: keyof typeof ANALYTICS_IDS) => ({
  'data-analytics': ANALYTICS_IDS[id],
})

export function useAnalytics() {
  const posthog = usePostHog()

  const trackPageView = () => {
    try {
      posthog.capture('$pageview')
    } catch (e) {
      console.error('ANALYTICS: Error tracking pageview', e)
    }
  }

  const trackEvent = (event: string, properties?: Properties | null) => {
    try {
      posthog.capture(event, properties)
    } catch (e) {
      console.error('ANALYTICS: Error tracking event', e, properties)
    }
  }

  const identifyUser = (pubkey: string, user?: IUser) => {
    try {
      posthog.identify(
        pubkey,
        user && {
          email: user.email,
          use_canonic_fulfillment: user.use_canonic_fulfillment,
          keys: user.keys,
        },
      )
    } catch (e) {
      console.error('ANALYTICS: Error identifying user', e)
    }
  }

  const clearUser = () => {
    try {
      posthog.reset()
    } catch (e) {
      console.error('ANALYTICS: Error clearing user', e)
    }
  }

  return { trackPageView, trackEvent, identifyUser, clearUser }
}
