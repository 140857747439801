import { XIcon } from '@heroicons/react/outline'
import { useClickAway, useWindowSize } from 'react-use'
import { useRef } from 'react'
import { Transition, Dialog } from '@headlessui/react'

interface Props {
  open: boolean
  onClose: () => void
  size?: 'xs' | 'sm' | 'md' | 'lg'
  children: React.ReactNode
}

export default function SideDrawer(props: Props) {
  const { open, onClose, children, size = 'sm' } = props

  const MAX_WIDTHS: Record<typeof size, string> = {
    xs: 'md:max-w-xs',
    sm: 'md:max-w-sm',
    md: 'md:max-w-md',
    lg: 'md:max-w-lg',
  }

  const { height: vh } = useWindowSize()

  const overlayRef = useRef(null)

  useClickAway(overlayRef, onClose)

  return (
    <Transition show={open}>
      <Dialog onClose={onClose}>
        {/* Backdrop */}
        <Transition.Child
          className="fixed inset-0 z-20 bg-black/30 transition-all dark:bg-black/95"
          enter="ease-out duration-150 transition-all"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in transition-all duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        />
        <Transition.Child
          style={{ height: `${vh}px` }}
          className={`fixed inset-y-0 right-0 z-30 flex w-full flex-col ${MAX_WIDTHS[size]} text-theme-body-gray bg-theme-body-white/80 overflow-y-scroll border shadow-lg backdrop-blur-lg dark:border-none dark:bg-gray-800/50 dark:text-gray-300`}
          enter="transition-transform"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transition-transform"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <Dialog.Panel className="relative flex-1">
            <button
              onClick={onClose}
              className="absolute right-1 top-1 p-1.5"
              type="button"
            >
              <XIcon className="dark:text-theme-body-white h-6 w-6 text-black" />
            </button>
            {children}
          </Dialog.Panel>
        </Transition.Child>
      </Dialog>
    </Transition>
  )
}
