import React from 'react'

export default function NotFoundPage() {
  return (
    <div className="flex h-full flex-1 justify-center px-3 pt-20">
      <h1 className="dark:text-theme-body-white text-center text-4xl font-semibold leading-none text-black">
        We can't find that page.
      </h1>
    </div>
  )
}
