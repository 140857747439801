import * as SimpleAes from 'simple-aes-256'
import SegWit from './utils/segwit'
import {
  BASE_PATHS,
  BSV,
  MASTER_PATHS,
  Rune,
  SATS_PER_BSV,
  bsv,
} from '@canonic/lib'
import { Ticker } from '@canonic/types'

const LOCAL_STORAGE = {
  KEYS: 'ark_keys',
  BASE_PATH: 'ark_base_path',
  MASTER_PATH: 'ark_master_path',
  LAST_SAVED_BALANCE: 'last_saved_balance',
} as const

export interface WalletUtxos {
  ticker: Ticker
  price: number
  currency: string
  total_usd: number
  total_sats: number
  spent_count: number
  unused_path: string
  last_block: {
    height: number
    hash: string
    time: number
    ago: string
  }

  dust_limit: number
  sats_per_kb: number
  sats_per_kb_min?: number
  sats_per_kb_btc_mempool?: {
    fastestFee: number
    halfHourFee: number
    hourFee: number
    economyFee: number
    minimumFee: number
  }
  utxos: {
    vout: number
    txid: string
    address: string
    path: string
    time: number
    satoshis: number
    height?: number
    amount_usd: number
  }[]
}

export type RuneUtxo = WalletUtxos['utxos'][number] & {
  rune: true
  rune_amount: number
}

export interface WalletHistory {
  ticker: Ticker
  price: number
  currency: string
  history_count: number
  history: {
    txid: string
    time: number
    satoshis: number
    type: 'sent' | 'received'
    height: number
    amount_usd: number
    ticker: Ticker
    parent_txid: string
    xpubhash?: string
  }[]
}

export interface WalletData extends WalletUtxos {
  history: WalletHistory['history']
}

interface RuneWalletUtxosOnChange extends WalletUtxos {
  utxos: RuneUtxo[]
}

export type OnWalletChange =
  | WalletUtxos
  | RuneWalletUtxosOnChange
  | (WalletHistory & { sse: EventSource })

export type OnAddressChange = Omit<WalletUtxos, 'unused_path'> & {
  sse: EventSource
}

export type CombinedWalletHistory = ReturnType<typeof getCombinedHistory>
export type AllWalletUtxos = ReturnType<typeof getAllUtxos>

export type RuneMap = Record<RuneName, Rune & { utxos: RuneUtxo[] }>

const dev = process.env.NODE_ENV === 'development'

// const BASE_URL = 'http://localhost:3000'
// const BASE_URL = 'http://192.168.1.186:3000'
const BASE_URL = 'https://wallet.ark.page'
// const CANONIC_BASE_URL = 'https://bsv1.canonic.xyz'
const DERIVE_MASTER = 'm/0'
// const DERIVE_MASTER = `m/44'/0'/0'/0/0/0`

// const pay = new BsvPay({ fetchFunc: window.fetch.bind(window) })

let utxos = {} as Record<Ticker, WalletUtxos>
let runes: RuneMap = {}
let pendingRuneUtxos: RuneUtxo[] = []
let history = {} as Record<Ticker, WalletHistory | null>
let sseConnected = false

const WALLET_URLS: Record<Ticker, string> = {
  BSV: 'https://bsv.canonic.xyz',
  BTC: 'https://btc.canonic.xyz',
  BCH: 'https://bch.canonic.xyz',
  SIGNET: 'https://signet.canonic.xyz',
}

const EVENTS = {
  WALLET_SSE_CONNECTED: 'canonicWalletSseConnected',
  RUNES_LOADED: 'canonicRunesLoaded',
}

function addEventListener(
  event: keyof typeof EVENTS,
  cb: (data: unknown) => void,
) {
  window.addEventListener(EVENTS[event], cb)
}

function formatDollars(satoshis: number, price: number) {
  let number = 0
  let string = ''

  if (satoshis === 0 || price === 0) {
    string = `$0.00`
    number = 0
  } else {
    const dollars = Number(price * (satoshis / SATS_PER_BSV))
    number = Number(`${dollars.toFixed(3)}`)
    if (dollars * 100 >= 99) {
      string = new Intl.NumberFormat('en-us', {
        style: 'currency',
        currency: 'USD',
      }).format(number)
    } else if (dollars >= 0.01) {
      string = `${Number(dollars * 100).toFixed(0)}¢`
    } else {
      string = `<1¢`
    }
  }

  return { string, number }
}

function formatBitcoin(satoshis: number, ticker: Ticker) {
  return `${parseFloat(
    (satoshis / SATS_PER_BSV).toFixed(4),
  )} ${ticker.toUpperCase()}`
}

function isAddress(address: string) {
  try {
    return bsv.Address.fromString(address).toString() === address
  } catch (err) {
    return false
  }
}

function isLoggedIn() {
  return !!localStorage.getItem(LOCAL_STORAGE.KEYS)
}

function getSeed() {
  const seed = localStorage.getItem(LOCAL_STORAGE.KEYS)
  if (!seed) throw Error(`Not logged in`)
  return seed
}

function setBasePath(basePath: string) {
  localStorage.setItem(LOCAL_STORAGE.BASE_PATH, basePath)
}

function rmBasePath() {
  localStorage.removeItem(LOCAL_STORAGE.BASE_PATH)
}

function setMasterPath(masterPath: string) {
  localStorage.setItem(LOCAL_STORAGE.MASTER_PATH, masterPath)
}

function rmMasterPath() {
  localStorage.removeItem(LOCAL_STORAGE.MASTER_PATH)
}

function getMasterPath() {
  return localStorage.getItem(LOCAL_STORAGE.MASTER_PATH) || DERIVE_MASTER
}

function getBasePath() {
  return localStorage.getItem(LOCAL_STORAGE.BASE_PATH) || BASE_PATHS.CANONIC
}

function setSeed(seed: string) {
  if (bsv.Bip39.fromString(seed).toString() !== seed)
    throw Error(`Invalid seed`)
  localStorage.setItem(LOCAL_STORAGE.KEYS, seed)
}

function logout() {
  localStorage.removeItem(LOCAL_STORAGE.KEYS)
  localStorage.removeItem('canonic_token')
  rmBasePath()
  rmMasterPath()
  utxos = {} as Record<Ticker, WalletUtxos>
  history = {} as Record<Ticker, WalletHistory>
  runes = {}
  pendingRuneUtxos = []
  disconnectUserSse()
  disconnectWalletSse()
  if (dev) console.log(`Logged out`)
}

function getKey(derived?: string, seed?: string): BSV.KeyPair {
  if (!seed) seed = getSeed()
  const bip39 = bsv.Bip39.fromString(seed)
  const bip32 = bsv.Bip32.fromSeed(bip39.toSeed())
  if (derived) {
    const basePath = getBasePath()
    const baseKey = bip32.derive(basePath)
    if (!(baseKey instanceof bsv.Bip32)) throw Error('Missing bip32!')

    const derivedKey = baseKey.derive(derived)
    return derivedKey as BSV.KeyPair
  } else {
    const masterPath = getMasterPath()
    const key = bip32.derive(masterPath)

    return key as BSV.KeyPair
  }
}

function getPubKey(derived?: string, seed?: string): string {
  const key = getKey(derived, seed)

  return key.pubKey.toString()
}

function getXpub(seed = getSeed(), basePath = getBasePath()) {
  const bip39 = bsv.Bip39.fromString(seed)
  const bip32 = bsv.Bip32.fromSeed(bip39.toSeed())
  if (basePath) {
    const xpub: string = bip32.derive(basePath).toPublic().toString()
    return { xpub, bip32, bip39, seed }
  } else {
    const xpub: string = bip32.toPublic().toString()
    return { xpub, bip32, bip39, seed }
  }
}

function getAuth(seed?: string) {
  const { xpub } = getXpub(seed)
  const msg = `${+new Date()}`
  const derived = MASTER_PATHS.CANONIC
  const key = getKey(derived, seed)
  const sig = bsv.Bsm.sign(Buffer.from(msg), key)
  return { xpub, key, derived, msg, sig }
}

interface ApiCall {
  url: string
  method?: string
  seed?: string
  auth?: boolean
  body?: object
}

async function apiCall({ url, method, seed, auth = false, body }: ApiCall) {
  const options: Record<any, any> = { headers: {} }
  if (body) {
    options.body = JSON.stringify(body)
    options.headers['Content-Type'] = 'application/json'
  }
  if (method) options.method = method
  if (auth) {
    const { xpub, msg, derived, sig } = getAuth(seed)
    options.headers.Authorization = `${xpub},${derived},${msg},${sig}`
  }
  const res = await fetch(`${BASE_URL}${url}`, options)
  const json = await res.json()
  if (!json || json.error) {
    throw Error((json && json.error) || `Unknown error`)
  }
  return json
}

interface EncryptBackup {
  email: string
  password: string
  seed: string
}

function encryptBackup({ email, password, seed }: EncryptBackup) {
  const key = Buffer.from(SimpleAes.sha256(`${email}:${password}`))
  const hash = Buffer.from(SimpleAes.sha256(key)).toString('hex')
  const backup = Buffer.from(SimpleAes.encryptRaw(key, seed)).toString('base64')
  return { key, hash, backup }
}

interface DecryptBackup {
  email: string
  password: string
  backup: string
}

function decryptBackup({ email, password, backup }: DecryptBackup) {
  const key = Buffer.from(SimpleAes.sha256(`${email}:${password}`))
  const hash = Buffer.from(SimpleAes.sha256(key)).toString('hex')
  const seed = SimpleAes.decryptRaw(
    key,
    Buffer.from(backup, 'base64'),
  ).toString()
  return { key, hash, seed }
}

function validSeed(seed: string) {
  try {
    const bip39 = bsv.Bip39.fromString(seed)
    bsv.Bip32.fromSeed(bip39.toSeed()) // Will throw error if invalid
    return bip39.toString() === seed
  } catch (err) {
    return false
  }
}

interface CreateAccount {
  email: string
  password: string
  seed?: string
  wallet?: 'canonic' | 'moneybutton' | 'relayx'
  /** The static paymail of the wallet that this the new user will be an authorizer for */
  authorizerFor?: string
}

async function createAccount({
  email,
  password,
  seed: existingSeed,
  wallet,
  authorizerFor,
}: CreateAccount) {
  if (!email || !password) throw new Error(`Invalid email or password`)
  email = email.toLowerCase().trim()

  let masterPath
  let basePath
  let singleAddress
  switch (wallet) {
    case 'canonic':
      masterPath = MASTER_PATHS.CANONIC
      basePath = BASE_PATHS.CANONIC
      break
    case 'moneybutton':
      masterPath = MASTER_PATHS.MONEYBUTTON
      basePath = BASE_PATHS.MONEYBUTTON
      break
    case 'relayx':
      masterPath = MASTER_PATHS.RELAYX
      basePath = BASE_PATHS.RELAYX
      singleAddress = true
      break
  }

  if (basePath) {
    setBasePath(basePath)
  } else {
    rmBasePath()
  }
  if (masterPath) {
    setMasterPath(masterPath)
  } else {
    rmMasterPath()
  }
  if (existingSeed && !validSeed(existingSeed))
    throw Error(`Invalid 12 word seed`)
  const seed = existingSeed || bsv.Bip39.fromRandom().toString()
  const masterKey = getKey(undefined, seed)
  const masterPubkey = masterKey.pubKey.toString()
  const { hash, backup } = encryptBackup({ email, password, seed })

  const { user } = await apiCall({
    url: '/api/v1/user',
    method: 'POST',
    auth: true,
    seed,
    body: {
      email,
      backup,
      hash,
      basePath,
      masterPath,
      masterPubkey,
      singleAddress,
      canonic: true,
      authorizer_for: authorizerFor,
    },
  })
  setSeed(seed)
  if (dev) {
    const { xpub } = getXpub()
    console.log(`Created new account`, seed, xpub)
  }

  return user
}

/** Verify the password of the currently signed in user */
async function verifyPassword(password: string) {
  try {
    const user = await getUser()
    if (!user) throw Error('No user found')

    const key = Buffer.from(SimpleAes.sha256(`${user.email}:${password}`))

    const hash = Buffer.from(SimpleAes.sha256(key)).toString('hex')
    const { backup } = await apiCall({
      url: `/api/v1/user/backup/${hash}`,
    })
    const builtSeed = SimpleAes.decryptRaw(
      key,
      Buffer.from(backup, 'base64'),
    ).toString()

    const seed = getSeed()

    if (seed !== builtSeed) throw Error('Invalid password')

    return true
  } catch (err) {
    console.error(err)
    return false
  }
}

interface Login {
  email: string
  password: string
  key?: string | null
  seed?: string
  overwriteExisting?: boolean
}

async function login({
  email,
  password,
  key: loginKey = null,
  seed: existingSeed,
}: Login) {
  if (existingSeed && validSeed(existingSeed)) {
    setSeed(existingSeed)
    if (dev) {
      console.log(`Logged in`, existingSeed)
    }
    return
  }
  if ((!email || !password) && !loginKey)
    throw new Error(`Invalid email or password`)

  email = email && email.toLowerCase().trim()
  const key = loginKey || Buffer.from(SimpleAes.sha256(`${email}:${password}`))

  const hash = Buffer.from(SimpleAes.sha256(key)).toString('hex')
  const { backup, xpub, basePath, masterPath } = await apiCall({
    url: `/api/v1/user/backup/${hash}`,
  })
  const seed = SimpleAes.decryptRaw(
    key,
    Buffer.from(backup, 'base64'),
  ).toString()
  if (basePath) {
    setBasePath(basePath)
  } else {
    rmBasePath()
  }
  if (masterPath) {
    setMasterPath(masterPath)
  } else {
    rmMasterPath()
  }
  setSeed(seed)
  if (dev) {
    console.log(`Logged in`, seed, xpub)
  }
  if (!xpub) {
    // Legacy. Server does not know xpub from backup
    try {
      await apiCall({
        url: '/api/v1/user',
        method: 'POST',
        auth: true,
        body: {
          email,
          backup,
          hash,
          basePath,
          masterPath,
          canonic: true,
        },
      })
    } catch (err) {
      console.log(err)
    }
  }
  return seed
}

function changePaymail(paymail: string) {
  return apiCall({
    url: `/api/v1/user/paymail`,
    auth: true,
    method: 'POST',
    body: {
      paymail,
    },
  })
}

function changeImage(image: any) {
  return apiCall({
    url: `/api/v1/user/image`,
    auth: true,
    method: 'POST',
    body: {
      image,
    },
  })
}

function getAllUtxos() {
  return utxos
}

// Exponential backoff for these functions since they
// require the SSE to be initialized before any data is present
const MAX_ATTEMPTS = 10

async function _getUtxos(ticker: Ticker, attempt = 1): Promise<WalletUtxos> {
  if (!sseConnected && attempt <= MAX_ATTEMPTS) {
    await new Promise((resolve) => setTimeout(resolve, 250 * attempt))
    return _getUtxos(ticker, attempt + 1)
  }
  return getAllUtxos()[ticker]
}

const getUtxos = (ticker: Ticker) => _getUtxos(ticker)

async function _getRunes(attempt = 1) {
  if (!sseConnected && attempt <= MAX_ATTEMPTS) {
    await new Promise((resolve) => setTimeout(resolve, 250 * attempt))
    return _getRunes(attempt + 1)
  }
  return { runes, pendingRuneUtxos }
}

const getRunes = () => _getRunes()

function getHighestBalanceUtxos() {
  let highestBalance = utxos['BSV']

  for (const ticker of Object.values(utxos)) {
    if (ticker.total_sats > highestBalance.total_sats) {
      highestBalance = ticker
    }
  }

  return highestBalance
}

async function getUser(): Promise<
  | {
      xpub: string
      email: string
      /** The static immutable paymail of the user's wallet */
      id: string
      image: string
      /** Pretty paymail */
      paymail: string
      pubKey: string
      sig: string
      signedMsg: string
      authorizer_paymail?: string | null
      authorizer_email?: string | null
      authorizer_for?: string | null
    }
  | undefined
> {
  if (isLoggedIn()) {
    const { user } = await apiCall({
      url: `/api/v1/user?canonic=1`,
      auth: true,
    })
    const auth = getAuth()
    user.pubKey = auth.key.pubKey.toString()
    user.signedMsg = auth.msg
    user.sig = auth.sig
    return user
  }
}

const PAYMAIL_API_BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:4000'
    : 'https://canonic.xyz'

async function lookupPaymail(paymail: string, satoshis = 10000) {
  if (!paymail) throw Error(`Invalid paymail`)
  // console.log(`Looking up paymail:`, paymail)
  if (paymail.toLowerCase().includes('@handcash')) {
    throw Error(`Cannot send to HandCash paymail`)
  }
  let paymailTx: {
    paymail: string
    outputs: { script: string; satoshis: number; reference: string }[]
  } | null = null

  try {
    const res = await fetch(
      `${PAYMAIL_API_BASE_URL}/api/paymail/p2p-payment-destination`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ paymail, satoshis }),
      },
    )
    paymailTx = await res.json()

    if (!paymailTx?.outputs[0].script) throw Error(`Not found`)
  } catch (err: any) {
    console.error(
      `Paymail lookup error: ${paymail} sats: ${satoshis}`,
      err,
      paymailTx,
    )
    throw Error(`No paymail address`)
  }
  const script = bsv.Script.fromBuffer(
    Buffer.from(paymailTx.outputs[0].script, 'hex'),
  )
  const address = bsv.Address.fromTxOutScript(script).toString()
  if (bsv.Address.fromString(address).toString() !== address) {
    throw new Error(`Invalid address`)
  }
  if (dev) {
    console.log(`Paymail ${paymail} address:`, address)
  }
  return { address, paymailTx }
}

function getHistory(ticker: Ticker) {
  const h = history[ticker]
  return h ? h.history.map((h) => ({ ...h, ticker })) : []
}

function getCombinedHistory({ showSignet = false } = {}) {
  let history: WalletHistory['history'] = [
    ...getHistory('BCH'),
    ...getHistory('BTC'),
    ...getHistory('BSV'),
  ]

  if (showSignet) {
    history = [...history, ...getHistory('SIGNET')]
  }

  history.sort((a, b) => b.time - a.time)
  return history
}

let sse: EventSource | null

function connectUserSse(onChange: (obj: any) => void) {
  const { xpub } = getXpub()
  disconnectUserSse()
  sse = new EventSource(`https://wallet.ark.page/api/v1/user/sse/${xpub}`)
  sse.onmessage = (event) => {
    const obj = JSON.parse(event.data)
    if (dev) {
      console.log(`sse message`, obj)
    }
    onChange(obj)
  }

  return sse
}

function getFirstPath() {
  return 'm/0/0'
}

function getChangePath() {
  const utxos = getAllUtxos()
  let unused_path = getFirstPath()
  Object.values(utxos).map((obj) => {
    if (obj && obj.unused_path) unused_path = obj.unused_path
  })
  return unused_path
}

function getChangeAddress() {
  const unused_path = getChangePath()
  const walletKey = getKey(unused_path)
  const changeAddress = bsv.Address.fromPrivKey(walletKey.privKey).toString()
  return changeAddress
}

function disconnectUserSse() {
  if (sse) {
    sse.close()
  }
}

async function getReservedUtxos() {
  const pubkey = getPubKey()
  const BASE_URL =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:3006'
      : 'https://fund.canonic.xyz'

  let utxos: { txid: string; vout: number }[] = []
  // try {
  //   const res = await fetch(`${BASE_URL}/api/reserved-utxos/${pubkey}`)
  //   const json = await res.json()

  //   utxos = json
  // } catch (err) {
  //   console.error(err)
  // }

  return utxos
}

let walletSseConnections: Record<string, Record<string, EventSource>> = {}

function connectWalletSse(
  onChange: (obj: OnWalletChange) => void,
  signet?: boolean,
) {
  const { xpub } = getXpub()

  connectSse(
    { xpub },
    (d) => {
      onChange(d as OnWalletChange)
      saveBalance()
    },
    { signet: !!signet },
  )
}

function connectAddressSse(
  address: string,
  onChange: (obj: OnAddressChange) => void,
) {
  connectSse({ address }, onChange, { signet: false })
}

const MAX_RETRIES = 10

function connectSse<T extends OnWalletChange | OnAddressChange>(
  options: { xpub: string } | { address: string },
  onChange: (obj: T) => void,
  { signet }: { signet: boolean },
) {
  const xpubOrAddress = 'xpub' in options ? options.xpub : options.address
  const wallet = 'xpub' in options

  let urls = Object.entries(WALLET_URLS)
  if (!signet) {
    urls = urls.filter(([ticker, url]) => (ticker as Ticker) !== 'SIGNET')
  }

  for (const [tick, url] of urls) {
    let retries = 0

    const ticker = tick as Ticker

    const connectSseUrl = () => {
      if (retries > MAX_RETRIES) return

      if (!walletSseConnections[xpubOrAddress])
        walletSseConnections[xpubOrAddress] = {}
      if (walletSseConnections[xpubOrAddress][ticker]) return

      const source = `${url}/sse/utxos/${xpubOrAddress}`
      const sse = new EventSource(source)
      walletSseConnections[xpubOrAddress][ticker] = sse

      sse.onmessage = async (event) => {
        const obj: T = JSON.parse(event.data)
        // if (dev) console.log(`wallet sse message`, obj)

        if (wallet) {
          if ('history' in obj) {
            history[ticker] = obj
          } else if ('unused_path' in obj) {
            sseConnected = true

            window.dispatchEvent(new CustomEvent(EVENTS.WALLET_SSE_CONNECTED))

            let blacklistedUtxos = [...(await getReservedUtxos())]

            // Utxos that are part of a runestone TX, but not necessarily a rune
            if (obj.ticker === 'BTC' || obj.ticker === 'SIGNET') {
              const possibleRuneUtxos = obj.utxos.filter(
                (u) => 'rune' in u && u.rune,
              ) as RuneUtxo[]

              const { foundUtxos } = await _fetchRunes(possibleRuneUtxos, {
                signet,
              })

              if (foundUtxos.length) {
                blacklistedUtxos = [...blacklistedUtxos, ...foundUtxos]
              }
              window.dispatchEvent(new Event(EVENTS.RUNES_LOADED))
            }

            if (blacklistedUtxos.length > 0) {
              const blacklistedIds = new Set(
                blacklistedUtxos.map((u) => `${u.txid}-${u.vout}`),
              )

              const filteredUtxos = obj.utxos.filter(
                (u) => !blacklistedIds.has(`${u.txid}-${u.vout}`),
              )

              utxos[ticker] = { ...obj, utxos: filteredUtxos, ticker }
            } else {
              utxos[ticker] = { ...obj, ticker }
            }
          }
        }
        onChange({ ...obj, sse })
      }

      const timeout = setTimeout(() => {
        sse.close()
        if (
          walletSseConnections &&
          walletSseConnections[xpubOrAddress] &&
          walletSseConnections[xpubOrAddress][ticker]
        ) {
          delete walletSseConnections[xpubOrAddress][ticker]
        }
        // Try to connect until the maxRetries is met
        retries++
        connectSseUrl()
      }, 5000)

      sse.onopen = () => clearTimeout(timeout)
      sse.onerror = () => {
        clearTimeout(timeout)
        sse.close()
        if (
          walletSseConnections &&
          walletSseConnections[xpubOrAddress] &&
          walletSseConnections[xpubOrAddress][ticker]
        ) {
          delete walletSseConnections[xpubOrAddress][ticker]
        }
        retries++
        connectSseUrl()
      }
    }

    connectSseUrl()
  }
}

function disconnectWalletSse(xpubOrAddress?: string) {
  if (xpubOrAddress) {
    if (walletSseConnections[xpubOrAddress]) {
      console.log(`Disconnecting from SSE ${xpubOrAddress}`)
      Object.values(walletSseConnections[xpubOrAddress]).map((sse) =>
        sse.close(),
      )
      delete walletSseConnections[xpubOrAddress]
    }
  } else {
    Object.keys(walletSseConnections).map((key: string) => {
      Object.values(walletSseConnections[key]).map((sse) => sse.close())
    })
    walletSseConnections = {}
  }
}

type RuneName = string

type RuneWithUxoInfo = Rune & RuneUtxo & { signet?: boolean }

/** Merge runes based on name */
function _mergeRunes(runes: RuneWithUxoInfo[]) {
  const runeMap: RuneMap = {}

  for (const rune of runes) {
    const existing = runeMap[rune.name]
    if (existing) {
      // We need to figure out what the latest rune id is
      const [existingRuneBlock, existingRuneTx] = existing.id.split(':')
      const [runeBlock, runeTx] = rune.id.split(':')
      const id =
        parseInt(existingRuneBlock) + parseInt(existingRuneTx) >
        parseInt(runeBlock) + parseInt(runeTx)
          ? existing.id
          : rune.id

      rune.rune_amount = rune.amount
      existing.amount += rune.amount
      existing.utxos.push(rune)
      existing.utxos.sort((a, b) => b.rune_amount - a.rune_amount)
      existing.id = id
    } else {
      runeMap[rune.name] = {
        name: rune.name,
        amount: rune.amount,
        signet: rune.signet,
        divisibility: rune.divisibility,
        symbol: rune.symbol,
        id: rune.id,
        preview_url: rune.preview_url,
        content_url: rune.content_url,
        utxos: [
          {
            txid: rune.txid,
            vout: rune.vout,
            satoshis: rune.satoshis,
            height: rune.height,
            address: rune.address,
            path: rune.path,
            time: rune.time,
            amount_usd: rune.amount_usd,
            rune: true,
            rune_amount: rune.amount,
          },
        ],
      }
    }
  }

  return runeMap
}

async function _fetchRunes(utxos: RuneUtxo[], { signet }: { signet: boolean }) {
  const ORD_URL = signet
    ? 'https://ord5-signet.canonic.xyz'
    : 'https://ord2.canonic.xyz'

  let foundUtxos = []
  let foundRunes: RuneWithUxoInfo[] = []
  let pendingUtxos = []

  for (const utxo of utxos) {
    const res = await fetch(
      `${ORD_URL}/runes/balance/${utxo.txid}/${utxo.vout}`,
    )
    const data: {
      runes: Rune[]
      txid: string
      vout: number
      address: string
      satoshis: number
    } = await res.json()

    if (!utxo.height) {
      pendingUtxos.push(utxo)
    }

    if (!utxo.height || ('runes' in data && data.runes.length)) {
      foundUtxos.push(utxo)
    }

    if ('runes' in data) {
      const validRunes = data.runes.map((r) => ({
        ...r,
        ...utxo,
        signet,
      }))

      foundRunes = [...foundRunes, ...validRunes]
    }
  }

  runes = _mergeRunes(foundRunes)
  pendingRuneUtxos = pendingUtxos

  return { foundUtxos }
}

function encrypt(data: any, dataEncoding: any, key = getKey()) {
  const buf = Buffer.from(data, dataEncoding)
  const encrypted = bsv.Ecies.electrumEncrypt(buf, key.pubKey, key)

  // console.log('encrypted: ', encryptedKey)
  return encrypted
}

function decrypt(data: any, dataEncoding: any, key = getKey()) {
  const buf = Buffer.from(data, dataEncoding)
  const decryptedKey = bsv.Ecies.electrumDecrypt(buf, key.privKey)

  // console.log('decrypted: ', decryptedKey)
  return decryptedKey
}

function sign(messageBuf: Buffer, derived?: string) {
  const signature = bsv.Bsm.sign(messageBuf, getKey(derived))

  return signature
}

function txBuilderBTC(txb: any) {
  function signWithKeyPairs(this: any, keyPairs: any) {
    // produce map of addresses to private keys
    const addressStrMap: Record<any, any> = {}
    for (const keyPair of keyPairs) {
      const addressStr = bsv.Address.fromPubKey(keyPair.pubKey).toString()
      addressStrMap[addressStr] = keyPair
    }
    // loop through all inputs
    for (const nIn in this.tx.txIns) {
      const txIn = this.tx.txIns[nIn]
      // for each input, use sigOperations to get list of signatures and pubkeys
      // to be produced and inserted
      const arr = this.sigOperations.get(txIn.txHashBuf, txIn.txOutNum)
      for (const obj of arr) {
        // for each pubkey, get the privkey from the privkey map and sign the input
        let { nScriptChunk, type, addressStr, nHashType } = obj
        const keyPair = addressStrMap[addressStr]
        if (!keyPair) {
          obj.log = `cannot find keyPair for addressStr ${addressStr}`
          continue
        }
        const txOut = this.uTxOutMap.get(txIn.txHashBuf, txIn.txOutNum)
        if (type === 'sig') {
          nHashType = ~bsv.Sig.SIGHASH_FORKID & nHashType
          const flags = 0
          this.signTxIn(nIn, keyPair, txOut, nScriptChunk, nHashType, flags)
          obj.log = 'successfully inserted signature'
        } else if (type === 'pubKey') {
          txIn.script.chunks[nScriptChunk] = new bsv.Script().writeBuffer(
            keyPair.pubKey.toBuffer(),
          ).chunks[0]
          txIn.setScript(txIn.script)
          obj.log = 'successfully inserted public key'
        } else {
          obj.log = `cannot perform operation of type ${type}`
          continue
        }
      }
    }
    return this
  }
  txb.signWithKeyPairs = signWithKeyPairs.bind(txb)
}

/** Gets the combined balance for all of the user's UTXOS */
function getBalance() {
  const allUtxos = getAllUtxos()
  const usd = Object.values(allUtxos).reduce(
    (total, curr) => (total += curr?.total_usd || 0),
    0,
  )

  const sats = Object.values(allUtxos).reduce(
    (total, curr) => (total += curr?.total_sats || 0),
    0,
  )

  return { usd, sats }
}

/** Save the most recent balance to local storage. Useful for offline mode */
function saveBalance() {
  const { usd, sats } = getBalance()

  localStorage.setItem(
    LOCAL_STORAGE.LAST_SAVED_BALANCE,
    JSON.stringify({ savedAt: +new Date(), usd, sats }),
  )
}

/** Useful for offline mode */
function getLastSavedBalance() {
  if (typeof window === 'undefined') return null
  const raw = localStorage.getItem(LOCAL_STORAGE.LAST_SAVED_BALANCE)

  if (!raw) return null

  const parsed: { savedAt: number; usd: number; sats: number } = JSON.parse(raw)
  return parsed
}

export interface ValidateXpubsResponse {
  query_seconds: number
  ticker: Ticker
  gap: number
  xpubs_matched: number
  /** Matched key-value pairs where the key is one of the provided xpubs,
   * and the value is the unused path */
  xpubs: Record<string, string>
}

async function validateXpubs(xpubs: string[]): Promise<ValidateXpubsResponse> {
  let error
  let response
  for (const [TICKR, url] of Object.entries(WALLET_URLS)) {
    try {
      const res = await fetch(`${url}/wallets?xpubs=${xpubs.join(',')}`)
      response = await res.json()
      if (response.xpubs_matched > 0) return response
    } catch (err) {
      error = err
    }
  }
  if (response) return response
  throw error
}

///////////////////////////////////
// Functions for BTC P2SH and SegWit support
///////////////////////////////////
function getP2SH(address: string) {
  const decoded = bsv.Base58.decode(address)
  if (Buffer.compare(decoded.subarray(0, 1), Buffer.from([5])) !== 0) {
    throw Error(`Not a P2SH address`)
  }
  const addressBuf = decoded.subarray(1, -4)
  const checkSum = decoded.subarray(-4)

  const check = bsv.Hash.sha256Sha256(decoded.subarray(0, -4)).subarray(0, 4)
  if (Buffer.compare(check, checkSum) !== 0) {
    console.log(check, checkSum)
    throw Error(`Invalid P2SH checksum`)
  }
  const scriptAsm = `OP_HASH160 ${addressBuf.toString('hex')} OP_EQUAL`
  return scriptAsm
}

function isP2SH(address: string) {
  try {
    return !!getP2SH(address)
  } catch (err) {}
  return false
}

function toP2SHAddressBuf(script: BSV.Script) {
  if (
    // P2SH Output
    script.chunks &&
    script.chunks.length === 3 &&
    script.chunks[0].opCodeNum === bsv.OpCode.OP_HASH160 &&
    script.chunks[1].buf &&
    script.chunks[1].buf.length === 20 &&
    script.chunks[2].opCodeNum === bsv.OpCode.OP_EQUAL
  ) {
    return script.chunks[1].buf
  }
}

const toP2SHAddress = (script: BSV.Script) => {
  const addressBuf = toP2SHAddressBuf(script)
  if (addressBuf) {
    let buf = Buffer.concat([Buffer.from([5]), addressBuf])
    const check = bsv.Hash.sha256Sha256(buf).slice(0, 4)
    buf = Buffer.concat([buf, check])
    return bsv.Base58.encode(buf)
  }
}

// SegWit

function getP2WPKH(address: string) {
  // bc1qjwx4qetrq4zw6qsmdqnyshkmkmrh92whg9k45x
  // 92d71ce2ed563580f092a348cb751830b6aa221bfc01654af439bd64f3855627
  try {
    const result = SegWit.decode('bc', address)
    if (!result) throw Error(`Not valid`)
    if (result.version !== 0) throw Error(`Invalid P2WPKH version`)
    if (result.program.length !== 20) throw Error(`Invalid P2WPKH length`)

    const addressBuf = Buffer.from(result.program)
    const scriptAsm = `OP_0 ${addressBuf.toString('hex')}`
    // console.log(`getP2WPKH: ${scriptAsm}`)
    // console.log(
    //   'getP2WPKH',
    //   bsv.Script.fromAsmString(scriptAsm).toBuffer().toString('hex')
    // )
    if (SegWit.encode('bc', 0, Array.from(addressBuf)) !== address) {
      throw Error(`Address does not match`)
    }
    return scriptAsm
  } catch (err) {
    // console.error(`getP2WPKH error`, err)
    throw err
  }
}

// function getAddressP2WPKH(address: string) {
//   try {
//     const result = SegWit.decode('bc', address)
//     if (result.version !== 0) throw Error(`Invalid P2WPKH version`)
//     if (result.program.length !== 20) throw Error(`Invalid P2WPKH length`)
//     const addressBuf = Buffer.from(result.program)
//     const addr = bsv.Address.fromPubKeyHashBuf(addressBuf)
//     return addr.toString()
//   } catch (err) {
//     console.error(`getAddressP2WPKH error`, err)
//     throw err
//   }
// }

function isP2WPKH(address: string) {
  try {
    return !!getP2WPKH(address)
  } catch (err) {}
  return false
}

function getP2WSH(address: string) {
  // bc1qlsv6fd7xs5qlf9w65ff8gv336rl89fxv4z525vdrx67f0ayl0g3q7hg5zg
  // 92d71ce2ed563580f092a348cb751830b6aa221bfc01654af439bd64f3855627
  try {
    const result = SegWit.decode('bc', address)
    if (!result) throw Error(`Not valid`)
    if (result.version !== 0) throw Error(`Invalid P2WSH version`)
    if (result.program.length !== 32) throw Error(`Invalid P2WSH length`)

    const scriptBuf = Buffer.from(result.program)
    const scriptAsm = `OP_0 ${scriptBuf.toString('hex')}`
    // console.log(`getP2WSH: ${scriptAsm}`)
    // console.log(
    //   'getP2WSH',
    //   bsv.Script.fromAsmString(scriptAsm).toBuffer().toString('hex')
    // )
    if (SegWit.encode('bc', 0, Array.from(scriptBuf)) !== address) {
      throw Error(`Address does not match`)
    }
    return scriptAsm
  } catch (err) {
    // console.error(`getP2WSH error`, err)
    throw err
  }
}

function isP2WSH(address: string) {
  try {
    return !!getP2WSH(address)
  } catch (err) {}
  return false
}

function getP2TR(address: string) {
  // bc1pkthl4l0e9cp4elwp6cxr9w0nz7kmz8nzjhzkwewtkwzyc46m4yxqx80zj7
  // 997d3d0773f1a3b39d5e47024ec5c206b25e67fd26f47f52fc099244f2c61d74
  try {
    const result = SegWit.decode('bc', address)
    if (!result) throw Error(`Not valid`)
    if (result.version !== 1) throw Error(`Invalid P2TR version`)
    if (result.program.length !== 32) throw Error(`Invalid P2TR length`)

    const scriptBuf = Buffer.from(result.program)
    const scriptAsm = `OP_1 ${scriptBuf.toString('hex')}`
    // console.log(`getP2TR: ${scriptAsm}`)
    // console.log(
    //   'getP2TR',
    //   bsv.Script.fromAsmString(scriptAsm).toBuffer().toString('hex')
    // )
    if (SegWit.encode('bc', 1, Array.from(scriptBuf)) !== address) {
      throw Error(`Address does not match`)
    }
    return scriptAsm
  } catch (err) {
    // console.error(`getP2TR error`, err)
    throw err
  }
}

function isP2TR(address: string) {
  try {
    return !!getP2TR(address)
  } catch (err) {}
  return false
}

function getBaseAddress() {
  return bsv.Address.fromPrivKey(Wallet.getKey().privKey)
}

// function txToSegWit(txhex: string, outputs: any) {
//   const bw = new bsv.Bw()
//   const br = new bsv.Br(Buffer.from(txhex, 'hex'))

//   const version = br.readInt32LE()
//   bw.writeInt32LE(version)

//   const sizeTxIns = br.readVarintNum()
//   bw.writeVarIntNum(0) // SegWit indicator
//   bw.writeUInt8(1) // segwitFlag must be set to 1
//   bw.writeVarIntNum(sizeTxIns)

//   for (let vin = 0; vin < sizeTxIns; vin++) {
//     const prevTxId = br.readReverse(32)
//     bw.writeReverse(prevTxId)
//     const vout = br.readUInt32LE()
//     bw.writeUInt32LE(vout)
//     const scriptBuffer = br.readVarLengthBuffer()
//     bw.writeVarIntBuffer(scriptBuffer)
//     const sequenceNumber = br.readUInt32LE()
//     bw.writeUInt32LE(sequenceNumber)
//   }

//   const sizeTxOuts = br.readVarintNum()
//   if (sizeTxOuts !== outputs.length) throw Error(`Missing segwit outputs`)
//   for (let vout = 0; vout < sizeTxOuts; vout++) {
//     const satoshis = br.readUInt64LEBn()
//     bw.writeUInt64LEBn(satoshis)

//     const { segwitScript } = outputs[vout]
//     if (segwitScript) {
//       const scriptBuffer = br.readVarLengthBuffer()
//       bw.writeVarIntBuffer(Buffer.from([])) // Empty script
//     } else {
//       const scriptBuffer = br.readVarLengthBuffer()
//       bw.writeVarIntBuffer(scriptBuffer)
//     }
//   }
//   for (let vout = 0; vout < sizeTxOuts; vout++) {
//     const { segwitScript } = outputs[vout]
//     if (segwitScript) {
//       bw.writeVarIntNum(1) // TODO: Check!!
//       const script = bsv.Script.fromAsmString(segwitScript)
//       bw.writeVarIntBuffer(script.toBuffer()) // TODO: Check!!
//     } else {
//       bw.writeVarIntNum(0)
//     }
//   }

//   const nLockTime = br.readUInt32LE()
//   bw.writeUInt32LE(nLockTime)
//   return bw.toBuffer().toString('hex')
// }
///////////////////////////////////
// Functions for BTC P2SH and SegWit support
///////////////////////////////////

export const Wallet = {
  addEventListener,
  createAccount,
  getUser,
  getSeed,
  getXpub,
  getPubKey,
  verifyPassword,
  login,
  logout,
  changePaymail,
  getUtxos,
  getAllUtxos,
  getRunes,
  getHighestBalanceUtxos,
  changeImage,
  lookupPaymail,
  getKey,
  isLoggedIn,
  getHistory,
  getCombinedHistory,
  formatDollars,
  formatBitcoin,
  isAddress,
  connectUserSse,
  disconnectUserSse,
  connectWalletSse,
  connectAddressSse,
  disconnectWalletSse,
  encrypt,
  decrypt,
  decryptBackup,
  encryptBackup,
  sign,
  DERIVE_MASTER,
  txBuilderBTC,
  getBalance,
  getLastSavedBalance,
  setBasePath,
  rmBasePath,
  getBasePath,
  setMasterPath,
  rmMasterPath,
  getMasterPath,
  validSeed,
  getFirstPath,
  getChangePath,
  getChangeAddress,
  getBaseAddress,
  validateXpubs,
  getP2SH,
  isP2SH,
  toP2SHAddressBuf,
  toP2SHAddress,
  getP2WPKH,
  isP2WPKH,
  getP2WSH,
  isP2WSH,
  getP2TR,
  isP2TR,
}
