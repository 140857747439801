import cx from 'classnames'
import React from 'react'
import { twMerge } from 'tailwind-merge'

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: string | boolean
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    const { error, ...rest } = props
    const errorClasses = cx({ 'ring-2 ring-red-500': !!error })

    return (
      <>
        <input
          {...rest}
          ref={ref}
          className={twMerge(`input w-full`, errorClasses, rest.className)}
        />
        {typeof error === 'string' && (
          <div className="text-red-500">{error}</div>
        )}
      </>
    )
  },
)

interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: string | boolean
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (props, ref) => {
    const { error, ...rest } = props
    const errorClasses = cx({ 'ring-2 ring-red-500': !!error })

    return (
      <>
        <textarea
          {...rest}
          ref={ref}
          className={twMerge(`input w-full`, errorClasses, rest.className)}
        />
        {typeof error === 'string' && (
          <div className="text-red-500">{error}</div>
        )}
      </>
    )
  },
)
