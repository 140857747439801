import { Wallet } from '@canonic/react'
import { atom, useAtom } from 'jotai'
import { useEffect } from 'react'

const walletSseConnectedAtom = atom(false)
const eventListenerConnectedAtom = atom(false)
const runesLoadedAtom = atom(false)

export function useWalletEvents() {
  const [walletSseConnected, setWalletSseConnected] = useAtom(
    walletSseConnectedAtom,
  )
  const [eventListenerAttached, setEventListenerAttached] = useAtom(
    eventListenerConnectedAtom,
  )
  const [runesLoaded, setRunesLoaded] = useAtom(runesLoadedAtom)

  useEffect(() => {
    if (!eventListenerAttached) {
      setEventListenerAttached(true)

      Wallet.addEventListener('WALLET_SSE_CONNECTED', () => {
        setWalletSseConnected(true)
      })

      Wallet.addEventListener('RUNES_LOADED', () => {
        setRunesLoaded(true)
      })
    }
  }, [eventListenerAttached])

  return {
    walletSseConnected,
    runesLoaded,
  }
}
