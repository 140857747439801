'use client'
import { ReactNode, SyntheticEvent } from 'react'

interface Props {
  text: string
  children: ReactNode
  onCopy?: (copiedText: string) => void
  /** Use right-click instead of primary click */
  rightClick?: boolean
  /** Disable the context menu if `rightClick` is enabled */
  disableContextMenu?: boolean
}

export function CopyToClipboard(props: Props) {
  const { children, onCopy, text, rightClick, disableContextMenu } = props

  const copy = async (e: SyntheticEvent<HTMLDivElement>) => {
    try {
      if (rightClick && disableContextMenu) e.preventDefault()

      await navigator.clipboard.writeText(text)
      if (onCopy) onCopy(text)
    } catch (err) {}
  }

  return (
    <div
      onClick={!rightClick ? copy : undefined}
      onContextMenu={rightClick ? copy : undefined}
    >
      {children}
    </div>
  )
}
