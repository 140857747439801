import { BroadcastPayload } from '@canonic/lib'
import { BFile } from '@canonic/types'
import { createTRPCReact } from '@trpc/react-query'
import type { AppRouter } from 'backend/src/trpc/app.router'

const TOKEN_NAME = 'canonic_token'

export const hasAPIToken = () => !!localStorage.getItem(TOKEN_NAME)
export const getAPIToken = () => localStorage.getItem(TOKEN_NAME)
export const setAPIToken = (token: string) =>
  localStorage.setItem(TOKEN_NAME, token)
export const clearApiToken = () => localStorage.removeItem(TOKEN_NAME)

interface ApiRequestOptions extends RequestInit {
  auth?: boolean
}

export const apiRequest = async (
  endpoint: string,
  options: ApiRequestOptions = {},
) => {
  const { auth } = options
  const headers = new Headers()

  const token = getAPIToken()
  if (auth && !token) throw Error(`Not logged in`)
  if (token) headers.append('authorization', `bearer ${token}`)

  headers.append('content-type', 'application/json')

  const res = await fetch(`/api${endpoint}`, {
    ...options,
    headers,
  })

  // if (res.status === 401) {
  //   clearApiToken()
  //   window.dispatchEvent(new Event('storage'))
  //   throw new Error(`Unauthorized`)
  // }
  return res
}

export const getImageUrl = (
  txidOrBFile: string | BFile | null,
  thumbnailVersion = false,
) => {
  if (!txidOrBFile) return ''
  return `/api/files/images/${
    typeof txidOrBFile === 'string' ? txidOrBFile : txidOrBFile.txid
  }${thumbnailVersion ? '?thumbnail=true' : ''}`
}

export const broadcastTx = async (
  payload: BroadcastPayload,
): Promise<{ txid: string }> => {
  const res = await apiRequest('/broadcast', {
    method: 'POST',
    body: JSON.stringify(payload),
  })
  const json = await res.json()
  if (json.error) throw new Error(json.error)
  if (!json.txid) throw new Error(`Missing txid`)
  return { txid: json.txid }
}

export const getDownloadUrl = async (
  productAddress: string,
  fileTxid: string,
  { viewOnly, admin }: { viewOnly?: boolean; admin?: string } = {},
) => {
  if (admin) {
    return {
      url: `/api/products/${productAddress}/download/${fileTxid}?admin=${admin}`,
    }
  }
  const res = await apiRequest(
    `/products/${productAddress}/download/${fileTxid}/verify`,
  )
  if (!String(res.status).startsWith('2')) {
    return null
  }

  const { token, isDecrypted } = await res.json()

  const url = `/api/products/${productAddress}/download/${fileTxid}?token=${token}${
    viewOnly ? '&viewOnly=true' : ''
  }`

  return { url, isDecrypted }
}

export const resizeImage = async (imageBuffer: ArrayBuffer) => {
  const res = await apiRequest('/cover-image-resize', {
    auth: true,
    method: 'POST',
    body: JSON.stringify({
      base64Image: Buffer.from(imageBuffer).toString('base64'),
    }),
  })

  return await res.json()
}

export const api = createTRPCReact<AppRouter>()
