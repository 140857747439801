import {
  ChevronDownIcon,
  MenuIcon,
  SearchIcon,
  ShoppingCartIcon,
} from '@heroicons/react/outline'
import { forwardRef, useState } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { IUser, PRODUCT_CATEGORIES } from '@canonic/lib'

// Components
import ProfileDropdown from './components/ProfileDropdown'
import MobileMenu from './components/MobileMenu'

// Lib
import { hasAPIToken } from 'lib/api'

import logo from '../../logo.png'
import useModal from 'hooks/useModal'
import { Routes } from 'components/AppRoutes'
import SearchBar from 'pages/Home/components/SearchBar'
import { Menu, Transition } from '@headlessui/react'
import cx from 'classnames'
import { useWindowScroll, useWindowSize } from 'react-use'
import { api } from 'lib/api'
import { useGuestCart } from 'hooks/useGuestCart'
import { Env } from 'lib/env'

export default function Navbar() {
  const { data: user, isLoading: isLoading } = api.user.get.useQuery(
    undefined,
    {
      enabled: hasAPIToken(),
    },
  )

  const { data: notifications } = api.user.notifications.useQuery(undefined, {
    enabled: hasAPIToken(),
  })

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [showMobileSearch, setShowMobileSearch] = useState(false)

  const unreadNotifications = notifications?.filter((n) => !n.seen).length

  const scrolling = useWindowScroll().y > 30

  const { width } = useWindowSize()

  const { guestPubKey } = useGuestCart()

  const { data: cart } = api.cart.get.useQuery(
    {
      guestPubKey,
    },
    { enabled: !!guestPubKey || hasAPIToken() },
  )

  const lineItemCount = cart?.line_items.reduce(
    (total, item) => (total += item.quantity),
    0,
  )
  return (
    <div
      className={`sticky inset-x-0 top-0 z-30 w-full bg-white bg-opacity-90 transition-all dark:bg-gray-800 ${cx(
        {
          'sm:bg-opacity-80 sm:shadow-lg sm:dark:bg-opacity-80 sm:dark:shadow-gray-900':
            scrolling,
          'sm:bg-opacity-0': !scrolling,
        },
      )}`}
    >
      <div className="absolute inset-0 backdrop-blur-lg" />
      <div
        className={`relative z-30 flex w-full items-center px-4 py-3 transition-all`}
      >
        <Link to="/" className="flex-shrink-0">
          <img src={logo} alt="Canonic" className="w-44" />
        </Link>
        <div className="ml-6 hidden flex-1 items-center text-lg lg:flex">
          <div className="flex items-center space-x-4">
            <div className="relative">
              <Menu>
                <Menu.Button className="dark:text-theme-body-white hover:text-theme-purple flex items-center text-black transition-colors">
                  Explore <ChevronDownIcon className="ml-1 h-5 w-5" />
                </Menu.Button>
                <Menu.Items className="absolute left-0 top-8 z-50 flex min-w-[10rem] origin-top-left flex-col divide-y overflow-hidden rounded-lg bg-gray-100 shadow-xl dark:divide-gray-600 dark:bg-gray-700 dark:shadow-gray-800">
                  <Menu.Item
                    as={StyledLink}
                    path={Routes.buildExploreRoute(
                      PRODUCT_CATEGORIES.PHYSICAL.BOOKS,
                    )}
                    title="Books"
                  />
                  <Menu.Item
                    as={StyledLink}
                    path={Routes.buildExploreRoute(
                      PRODUCT_CATEGORIES.DIGITAL.AUDIOBOOKS,
                    )}
                    title="Audiobooks"
                  />
                  <Menu.Item
                    as={StyledLink}
                    path={Routes.buildExploreRoute(
                      PRODUCT_CATEGORIES.DIGITAL.EBOOKS,
                    )}
                    title="eBooks"
                  />
                  <Menu.Item
                    as={StyledLink}
                    path={Routes.buildExploreRoute(
                      PRODUCT_CATEGORIES.DIGITAL.MUSIC,
                    )}
                    title="Music"
                  />
                  <Menu.Item
                    as={StyledLink}
                    path={Routes.buildExploreRoute(
                      PRODUCT_CATEGORIES.UNIVERSAL.MAGAZINES,
                    )}
                    title="Magazines"
                  />
                  {!Env.PROD && (
                    <Menu.Item
                      as={StyledLink}
                      path={Routes.buildExploreRoute(
                        PRODUCT_CATEGORIES.DIGITAL.MOVIES,
                      )}
                      title="Movies"
                    />
                  )}
                  <Menu.Item
                    as={StyledLink}
                    path={Routes.COLLECTIBLES}
                    title="Collectibles"
                  />
                </Menu.Items>
              </Menu>
            </div>
            {/* <StyledLink
              path={Routes.buildExploreRoute(PRODUCT_CATEGORIES.PHYSICAL.BOOKS)}
              title="Books"
            />
            <StyledLink
              path={Routes.buildExploreRoute(
                PRODUCT_CATEGORIES.DIGITAL.AUDIOBOOKS,
              )}
              title="Audiobooks"
            />
            <StyledLink
              path={Routes.buildExploreRoute(PRODUCT_CATEGORIES.DIGITAL.EBOOKS)}
              title="eBooks"
            />
            <StyledLink
              path={Routes.buildExploreRoute(PRODUCT_CATEGORIES.DIGITAL.MUSIC)}
              title="Music"
            />
            {!prod && (
              <StyledLink
                path={Routes.buildExploreRoute(
                  PRODUCT_CATEGORIES.DIGITAL.MOVIES,
                )}
                title="Movies"
              />
            )} */}
            <StyledLink path={Routes.COLLECTIBLES} title="Collectibles" />
            {/* <StyledLink path={Routes.GUTENBERG} title="Gutenberg" special /> */}
          </div>

          {!showMobileSearch && (
            <div className="mx-4 flex w-full items-center justify-end">
              <SearchBar />
            </div>
          )}
          {isLoading ? (
            <div className="w-32 animate-pulse rounded-lg bg-gray-200 py-5 dark:bg-gray-600/50" />
          ) : user ? (
            <LoggedIn user={user} unreadNotifications={unreadNotifications} />
          ) : (
            <LoggedOut />
          )}
        </div>

        <button
          onClick={() => setShowMobileSearch(!showMobileSearch)}
          className="relative ml-auto block h-6 w-6 focus:outline-none lg:hidden"
        >
          <SearchIcon />
        </button>
        <Link to={Routes.CART} className="relative ml-4" data-testid="cartLink">
          <ShoppingCartIcon className="h-5 w-5" />
          {lineItemCount && lineItemCount > 0 ? (
            <div className="text-theme-body-white bg-theme-purple absolute -right-1 -top-1 -mr-0.5 -mt-0.5 flex h-4 w-4 items-center justify-center rounded-full p-px text-xs">
              {lineItemCount}
            </div>
          ) : null}
        </Link>
        <button
          onClick={() => setDrawerOpen(true)}
          className="relative ml-4 block h-6 w-6 focus:outline-none lg:hidden"
          data-testid="mobileMenuButton"
        >
          {!!unreadNotifications && (
            <div className="bg-theme-purple absolute right-0 top-0 flex h-3.5 w-3.5 items-center justify-center rounded-full text-xs text-white">
              {unreadNotifications}
            </div>
          )}
          <MenuIcon />
        </button>
      </div>
      {/* Mobile Search */}
      <Transition
        show={showMobileSearch}
        className="relative z-0 flex w-full items-center justify-between bg-transparent px-4 pb-2 shadow transition-all lg:px-12 dark:shadow-gray-900"
        enterFrom="-translate-y-full"
        enterTo="translate-y-0"
        leaveFrom="translate-y-0"
        leaveTo="-translate-y-full"
      >
        <SearchBar />
        <button
          onClick={() => setShowMobileSearch(false)}
          className="text-theme-purple ml-2"
        >
          Cancel
        </button>
      </Transition>
      {width <= 1024 && (
        <MobileMenu
          user={user}
          open={drawerOpen}
          setOpen={setDrawerOpen}
          unreadNotifications={unreadNotifications}
        />
      )}
    </div>
  )
}

function LoggedIn(props: { user: IUser; unreadNotifications?: number }) {
  const { user, unreadNotifications } = props

  return (
    <>
      <Link
        to={Routes.ADMIN.PRODUCTS}
        className="btn-primary mr-4 px-8"
        data-testid="navbarAdminLink"
      >
        Publish
      </Link>
      <ProfileDropdown user={user} unreadNotifications={unreadNotifications} />
    </>
  )
}

function LoggedOut() {
  const { setModal } = useModal()
  return (
    <div className="flex items-center space-x-4">
      <button onClick={() => setModal('login')} className="btn-white px-6">
        Login
      </button>
      <button onClick={() => setModal('signup')} className="btn-primary px-6">
        Sign up
      </button>
    </div>
  )
}

const StyledLink = forwardRef(function StyledLink(
  {
    path,
    title,
    ...rest
  }: {
    path: string
    title: string
    special?: boolean
  },
  ref,
) {
  const location = useLocation()
  const active = location.pathname.includes(path)

  return (
    <NavLink
      to={path}
      className={`hover:text-theme-purple dark:hover:text-theme-purple relative whitespace-nowrap px-2 py-1 dark:hover:brightness-125 ${cx(
        { 'text-theme-purple dark:brightness-125': active },
      )}`}
      // Need to do this so the menu handles the refs accordingly
      ref={ref as any}
      {...rest}
    >
      {title}
    </NavLink>
  )
})
