import { IBFile } from '@canonic/lib'
import React, { useMemo } from 'react'
import { Accept, useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'
import { twMerge } from 'tailwind-merge'

const activeStyle = {
  borderColor: '#2196f3',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

const withoutExtension = (name: string) =>
  name.substring(0, name.lastIndexOf('.')) || name

interface Props {
  children: React.ReactNode
  onDrop: (droppedFiles: File[]) => void
  accept: Accept
  className?: string
  /** If provided, the drop zone will not allow a file if it already exists in the ignoreList (based off `name`) */
  ignoreList?: (File | IBFile)[]
  removeExtension?: boolean
  testId?: string
  maxFiles?: number
}
export function FileDrop(props: Props) {
  const {
    children,
    onDrop,
    className,
    accept,
    ignoreList,
    removeExtension,
    testId,
    maxFiles,
  } = props

  const onDropAccepted = (droppedFiles: File[]) => {
    let files = droppedFiles

    if (removeExtension) {
      files = files.map(
        (f) =>
          new File([f], withoutExtension(f.name), {
            lastModified: f.lastModified,
            type: f.type,
          }),
      )
    }

    if (ignoreList) {
      const unique = files.filter(
        (f) => !ignoreList.map((f) => f.name).includes(f.name),
      )
      if (unique.length < files.length)
        toast.warn('Some files could not be added (they might already exist).')

      files = unique
    }

    onDrop(files)
  }
  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragActive,
    isDragReject,
  } = useDropzone({
    maxFiles,
    onDropAccepted,
    accept,
  })

  const style = useMemo(
    () => ({
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  )
  return (
    <div
      className={twMerge(
        `dark:text-theme-body-white hover:border-theme-purple flex max-h-96 flex-1 cursor-pointer flex-col items-center justify-center space-y-1 rounded-md border-2 border-dashed border-gray-400 px-4 text-center text-black transition-colors focus:outline-none`,
        className,
      )}
      {...getRootProps({ style })}
    >
      <input {...getInputProps()} data-testid={testId} />
      {children}
    </div>
  )
}
