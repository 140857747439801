import { useEffect } from 'react'
import useModal from 'hooks/useModal'
import useAuth from 'hooks/useAuth'
import { ANALYTICS } from 'lib/constants'
import { Modal, Authentication } from '@canonic/react'

export default function LoginModal() {
  const { modal, setModal } = useModal()
  const { login, closeLogin } = useAuth()

  const isSignup = modal.name === 'signup'

  const open = modal.name === 'login' || modal.name === 'signup'

  useEffect(() => {
    if (!open) return

    try {
      if (isSignup) {
        window.umami.track(ANALYTICS.VIEWS.SIGNUP)
      } else {
        window.umami.track(ANALYTICS.VIEWS.LOGIN)
      }
    } catch (err) {
      console.error(`Analytics error: ${err}`)
    }
  }, [isSignup, open])

  return (
    <Modal
      title={isSignup ? 'Sign up' : 'Login'}
      open={open}
      onClose={closeLogin}
      size="lg"
    >
      <div>
        <Authentication
          screen={isSignup ? 'signup' : 'login'}
          formSubmitAnalyticsEvent={
            isSignup
              ? ANALYTICS.EVENTS.SUBMIT_SIGNUP
              : ANALYTICS.EVENTS.SUBMIT_LOGIN
          }
          onSuccess={() => login(isSignup)}
          signupLink={() => (
            <button
              onClick={() => setModal('signup', modal.data)}
              className="dark:text-theme-body-white text-base text-black underline"
              type="button"
              data-testid="signupButton"
            >
              I don't have an account
            </button>
          )}
          loginLink={() => (
            <button
              onClick={() => setModal('login', modal.data)}
              className="dark:text-theme-body-white text-base text-black underline"
              type="button"
            >
              I already have an account
            </button>
          )}
        />
      </div>
    </Modal>
  )
}
