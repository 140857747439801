import React from 'react'
import { twMerge } from 'tailwind-merge'
import cx from 'classnames'

interface Props extends React.SelectHTMLAttributes<HTMLSelectElement> {
  error?: string | boolean
}

export const SelectBox = React.forwardRef<HTMLSelectElement, Props>(
  (props, ref) => {
    const { error, ...rest } = props

    const errorClasses = cx({ 'ring-2 ring-red-500': !!error })
    return (
      <>
        <select
          {...rest}
          ref={ref}
          className={twMerge(
            'input pointer-events-auto block w-full cursor-pointer appearance-none transition-colors enabled:hover:bg-gray-300 disabled:cursor-default enabled:dark:hover:bg-gray-600',
            errorClasses,
            rest.className,
          )}
        >
          {props.children}
        </select>

        {typeof error === 'string' && (
          <div className="text-red-500">{error}</div>
        )}
      </>
    )
  },
)
