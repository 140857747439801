import { atom, useAtom } from 'jotai'
import { Wallet } from '@canonic/react'

const profileImageAtom = atom<string | null>(null)

export default function useProfileImage() {
  const [profileImage, setProfileImage] = useAtom(profileImageAtom)

  const refreshProfileImage = async () => {
    try {
      const u = await Wallet.getUser()
      if (u) {
        setProfileImage(`${u.image}?buster=${+new Date()}`)
      }
    } catch (err) {
      console.error(err)
    }
  }

  return {
    profileImage,
    refreshProfileImage,
  }
}
