'use client'
import { useEffect, useState } from 'react'
import cx from 'classnames'
import { toast } from 'react-toastify'
import {
  DocumentDuplicateIcon,
  EyeIcon,
  EyeSlashIcon,
} from '@heroicons/react/24/outline'
import { Wallet } from '../Wallet'
import { CopyToClipboard } from './CopyToClipboard'
import { Modal } from './Modal'

interface Props {
  open: boolean
  onClose: () => void
}

export function SeedPhraseModal({ open, onClose }: Props) {
  const [showSeed, setShowSeed] = useState(false)
  const [paymail, setPaymail] = useState('')
  const [seed, setSeed] = useState('')
  const [xpub, setXpub] = useState('')
  const [path, setPath] = useState('')

  useEffect(() => {
    const seed = Wallet.getSeed()
    const { xpub } = Wallet.getXpub()
    const path = Wallet.getBasePath()

    setSeed(seed)
    setXpub(xpub)
    setPath(path)

    const getPaymail = async () => {
      const user = await Wallet.getUser()
      if (user) {
        setPaymail(user.id)
      }
    }

    getPaymail()
  }, [])

  useEffect(() => {
    if (!open) {
      setShowSeed(false)
    }
  }, [open])

  return (
    <Modal title="Seed phrase" open={open} onClose={onClose}>
      <div className="mt-6 flex w-full flex-col items-center space-y-6 px-4">
        <div className="flex flex-col items-center space-y-4 rounded-lg border p-3">
          <div className={`${cx({ 'blur-sm': !showSeed })} text-center`}>
            {seed}
          </div>
          <button
            className="dark:text-theme-body-white flex items-center text-black"
            onClick={() => setShowSeed(!showSeed)}
          >
            {showSeed ? (
              <EyeSlashIcon className="mr-2 h-5 w-5" />
            ) : (
              <EyeIcon className="mr-2 h-5 w-5" />
            )}
            {showSeed ? 'Hide your seed phrase' : 'View your seed phrase'}
          </button>
        </div>
        <CopyToClipboard
          text={seed}
          onCopy={() => toast.success('Seed phrase copied!')}
        >
          <button className="btn-primary mt-6 flex items-center">
            <DocumentDuplicateIcon className="mr-2 h-5 w-5" />
            Copy seed phrase
          </button>
        </CopyToClipboard>
        <div className="mt-8" />
        <ul className="max-w-md list-outside list-disc">
          <li className="underline">
            Anyone who has access to your seed phrase can access your wallet.
          </li>
          <li>
            We strongly recommend writing down your phrase and storing it in a
            safe location.
          </li>
          <li>
            Canonic does not store and will never ask for your seed phrase.
          </li>
        </ul>
        <div className="dark:text-theme-body-white break-all rounded-lg border p-4 text-xs text-black">
          Id paymail:{' '}
          <CopyToClipboard
            text={paymail}
            onCopy={() => toast.success('Copied!')}
          >
            {paymail}
          </CopyToClipboard>
          <br />
          <br />
          {xpub}
          <br />
          <br />
          Derived path: {path || 'm'}
          <br />
          <br />
          Bitcoin Keys: {path ? `${path}/0/0` : 'm/0/0'}
        </div>
      </div>
    </Modal>
  )
}
