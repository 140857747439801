import { Link, useLocation } from 'react-router-dom'
import { IUser, PRODUCT_CATEGORIES } from '@canonic/lib'
import SideDrawer from '../../SideDrawer'
import ProfileMenu from './ProfileMenu'
import useModal from 'hooks/useModal'
import { Routes } from 'components/AppRoutes'
import { switchModals } from 'lib/modals'
import { useEffect } from 'react'
import { Popover } from '@headlessui/react'

interface Props {
  open: boolean
  user: IUser | undefined
  setOpen: (isOpen: boolean) => void
  unreadNotifications?: number
}

export default function MobileMenu(props: Props) {
  const { open, setOpen, user, unreadNotifications } = props
  const { setModal } = useModal()

  const { pathname } = useLocation()

  useEffect(() => {
    setOpen(false)
  }, [pathname])

  return (
    <SideDrawer open={open} onClose={() => setOpen(false)} size="xs">
      <div className="flex h-full flex-col py-4">
        {user && (
          <Popover>
            <Popover.Panel static>
              <ProfileMenu unreadNotifications={unreadNotifications} />
            </Popover.Panel>
          </Popover>
        )}
        <div className="dark:text-theme-body-white mt-auto flex flex-col self-start px-4 text-lg text-black">
          <div className="text-theme-gray text-sm dark:text-gray-400">Menu</div>

          <Link
            to={Routes.buildExploreRoute(PRODUCT_CATEGORIES.PHYSICAL.BOOKS)}
            className="py-1"
          >
            Books
          </Link>
          <Link
            to={Routes.buildExploreRoute(PRODUCT_CATEGORIES.DIGITAL.AUDIOBOOKS)}
            className="py-1"
          >
            Audiobooks
          </Link>
          <Link
            to={Routes.buildExploreRoute(PRODUCT_CATEGORIES.DIGITAL.EBOOKS)}
            className="py-1"
          >
            eBooks
          </Link>
          <Link
            to={Routes.buildExploreRoute(PRODUCT_CATEGORIES.DIGITAL.MUSIC)}
            className="py-1"
          >
            Music
          </Link>
          <Link
            to={Routes.buildExploreRoute(
              PRODUCT_CATEGORIES.UNIVERSAL.MAGAZINES,
            )}
            className="py-1"
          >
            Magazines
          </Link>
          <Link to={Routes.COLLECTIBLES} className="py-1">
            Collectibles
          </Link>
          {/* <Link
            onClick={() => setOpen(false)}
            to={Routes.GUTENBERG}
            className="py-1 text-theme-purple dark:brightness-125"
          >
            Gutenberg
          </Link> */}
        </div>
        <div className="mt-4 flex flex-col items-center justify-center space-y-4 px-4">
          {!user ? (
            <>
              <button
                onClick={() => {
                  switchModals(
                    () => setOpen(false),
                    () => setModal('login'),
                  )
                }}
                className="btn-white w-full"
                data-testid="mobileLoginButton"
              >
                Login
              </button>
              <button
                onClick={() => {
                  switchModals(
                    () => setOpen(false),
                    () => setModal('signup'),
                  )
                }}
                className="btn-primary w-full"
                data-testid="mobileSignupButton"
              >
                Sign up
              </button>
            </>
          ) : (
            <Link
              to={Routes.ADMIN.NEW_PRODUCT}
              className="btn-primary hidden w-full text-center md:block"
            >
              Publish
            </Link>
          )}
        </div>
      </div>
    </SideDrawer>
  )
}
