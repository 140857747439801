import Spinner from './components/Spinner'

interface Props {
  className?: string
}

export function LoadingIndicator(props: Props) {
  const { className } = props

  return <Spinner className={className} />
}
