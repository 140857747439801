import { SeedPhraseModal, Wallet } from '@canonic/react'
import React, { useState } from 'react'
import logo from '../logo.png'
import { formatCurrency } from '@canonic/lib'
import { format } from 'date-fns'

export default function Offline() {
  const loggedIn = Wallet.isLoggedIn()
  const balance = Wallet.getLastSavedBalance()
  const [seedPhraseModal, setSeedPhraseModal] = useState(false)

  return (
    <div className="flex min-h-screen flex-col items-center px-4 py-3">
      <img src={logo} alt="Canonic" className="w-44" />
      <div className="flex flex-1 flex-col items-center justify-center">
        <h1 className="text-2.5xl dark:text-theme-body-white mt-4 text-black">
          Offline
        </h1>
        <p className="text-center">
          You are not connected to the internet. Connect to the internet to
          experience all of Canonic's features.
        </p>
        {loggedIn && (
          <>
            {balance && (
              <div className="mt-4 flex flex-col items-center">
                <div>Balance: {formatCurrency(balance.usd)}</div>
                <div className="text-sm italic">
                  Last updated: {format(balance.savedAt, 'MM/dd/yy h:mma')}
                </div>
              </div>
            )}

            <button
              className="btn-primary mt-4"
              onClick={() => setSeedPhraseModal(true)}
            >
              View seed phrase
            </button>
            <SeedPhraseModal
              open={seedPhraseModal}
              onClose={() => setSeedPhraseModal(false)}
            />
          </>
        )}
      </div>
    </div>
  )
}
