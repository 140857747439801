import { LoadingIndicator } from './LoadingIndicator/LoadingIndicator'

function NotFound() {
  return (
    <div className="text-center">
      <h3 className="text-5xl font-medium">404</h3>
      <div className="mt-4">We can't find what you're looking for.</div>
    </div>
  )
}

export function LoadingView({ notFound }: { notFound?: boolean }) {
  return (
    <div className="mt-8 flex flex-col items-center justify-center">
      {notFound ? <NotFound /> : <LoadingIndicator />}
    </div>
  )
}
