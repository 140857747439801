import { Combobox } from '@headlessui/react'
import { getImageUrl } from 'lib/api'
import { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import { api } from 'lib/api'
import { LoadingIndicator } from '@canonic/react'
import { Routes } from 'components/AppRoutes'
import { IProduct, PRODUCT_CATEGORY_LABELS } from '@canonic/lib'

export default function SearchBar() {
  const [search, setSearch] = useState('')

  const { data, isLoading, isFetching } = api.products.all.useQuery(
    { search },
    { enabled: !!search },
  )
  const timerRef = useRef<NodeJS.Timeout>()

  const debounceSearch = (search: string) => {
    clearTimeout(timerRef.current)

    timerRef.current = setTimeout(() => {
      setSearch(search)
    }, 250)
  }

  const [selectedResult, setSelectedResult] = useState()
  return (
    <div className="relative w-full sm:max-w-sm">
      <Combobox>
        <Combobox.Input
          className="input w-full px-4 text-left"
          placeholder="Search by name or product address"
          onChange={(e) => debounceSearch(e.target.value)}
        />

        <Combobox.Options
          className={`absolute left-0 top-full z-10 mt-2 w-full overflow-hidden rounded-lg bg-white/90 shadow-lg backdrop-blur-lg dark:bg-gray-700/90`}
        >
          <div className="max-h-96 divide-y overflow-y-auto dark:divide-gray-600">
            {(isLoading || isFetching) && (
              <div className="flex items-center justify-center py-2">
                <LoadingIndicator />
              </div>
            )}
            {data?.products.map((product, i) => (
              <SearchResult
                key={JSON.stringify(product)}
                product={product}
                i={i}
                resultsLength={data.products.length}
              />
            ))}
            {data && !data.products.length && (
              <div className="flex p-3 text-base">No results found.</div>
            )}
          </div>
        </Combobox.Options>
      </Combobox>
    </div>
  )
}

function SearchResult({
  product,
  i,
  resultsLength,
}: {
  product: IProduct
  i: number
  resultsLength: number
}) {
  const { data: store } = api.store.single.useQuery({
    address: product.store_address,
  })
  return (
    <Combobox.Option
      as={Link}
      value={product}
      to={`${Routes.buildProductRoute(product.address)}`}
      className={`hover:border-theme-purple flex items-center space-x-2 border-2 border-transparent px-3 py-2 ${cx(
        {
          'rounded-t-lg': i === 0,
          'rounded-b-lg': i === resultsLength - 1,
        },
      )}`}
    >
      <img src={getImageUrl(product.thumbnail_image)} alt="" className="w-16" />
      <div className="flex flex-col overflow-hidden text-base">
        <div className="mb-1 self-start rounded bg-gray-200 px-2 py-px text-xs uppercase tracking-wide text-gray-900 shadow-none dark:bg-gray-400">
          {PRODUCT_CATEGORY_LABELS[product.category]}
        </div>
        <div
          className={`dark:text-theme-body-white truncate text-base font-bold leading-tight text-black transition`}
        >
          {product.name}
        </div>
        <div className="truncate">{store?.name ?? ''}</div>
      </div>
    </Combobox.Option>
  )
}
