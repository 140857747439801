import React from 'react'
import { LoadingIndicator } from './LoadingIndicator/LoadingIndicator'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /** Will show a loading indicator and disable the button when true. */
  loading?: boolean
}

export function Button(props: Props) {
  const { loading, ...rest } = props

  return (
    <button {...rest} disabled={props.disabled || loading}>
      {props.children}
      {loading && <LoadingIndicator className="ml-2 h-5 w-5 text-inherit" />}
    </button>
  )
}
