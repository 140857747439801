import usePaymail from 'hooks/usePaymail'
import useProfileImage from 'hooks/useProfileImage'
import { Link } from 'react-router-dom'
import { useNotifications } from 'hooks/hooks'
import { Routes } from 'components/AppRoutes'
import { switchModals } from 'lib/modals'
import useAuth from 'hooks/useAuth'
import { api } from 'lib/api'
import { Popover } from '@headlessui/react'

interface Props {
  unreadNotifications?: number
}

export default function ProfileMenu(props: Props) {
  const { unreadNotifications } = props
  const [, setNotificationsOpen] = useNotifications()
  const { profileImage } = useProfileImage()
  const { paymail } = usePaymail()
  const { logout } = useAuth()

  const { data: user } = api.user.get.useQuery()

  return (
    <div>
      <Popover.Button
        as={Link}
        to={Routes.PROFILE.WALLET}
        className="block border-b px-4 pb-4 pt-4 transition-colors hover:bg-gray-100 md:px-8 dark:border-gray-600 dark:hover:bg-gray-600"
        data-testid="profileLink"
      >
        <div className="flex items-center space-x-4">
          <div className="h-10 w-10">
            <img
              src={profileImage || ''}
              alt="Profile"
              className="h-full w-full rounded-full"
            />
          </div>
          <div className="flex flex-col justify-center">
            <div className="dark:text-theme-body-white max-w-[200px] truncate text-lg font-medium text-black">
              {paymail}
            </div>
          </div>
        </div>
      </Popover.Button>

      <div className="flex justify-between space-x-12 px-10 pt-4 text-lg md:px-8">
        <div className="flex flex-col">
          {/* <Popover.Button
            as={Link}
            to={Routes.PROFILE.BASE}
            className="text-sm text-theme-dark-gray self-start whitespace-nowrap dark:text-gray-400 hover:underline"
          >
            Profile
          </Popover.Button> */}
          <NavLink to={Routes.PROFILE.WALLET} label="Wallet" />
          <Popover.Button
            onClick={() => {
              setNotificationsOpen(true)
            }}
            className="dark:text-theme-body-white flex items-center py-0.5 text-black"
          >
            Notifications
            {!!unreadNotifications && (
              <div className="bg-theme-purple ml-1 flex h-5 w-5 items-center justify-center rounded-full text-sm text-white">
                {unreadNotifications}
              </div>
            )}
          </Popover.Button>
          {user?.admin && <NavLink to={Routes.DASHBOARD} label="Dashboard" />}
        </div>
        <div className="flex flex-col">
          <NavLink to={Routes.PROFILE.PURCHASES.ORDERS} label="Purchases" />
          <NavLink to={Routes.PROFILE.COLLECTIBLES} label="Collectibles" />
          <NavLink to={Routes.PROFILE.HISTORY} label="Transactions" />
          {/* <Popover.Button
            as={Link}
            to={Routes.ADMIN.BASE}
            className="text-sm text-theme-dark-gray dark:text-gray-400 self-start hover:underline"
          >
            Account
          </Popover.Button> */}
        </div>
      </div>

      <div className="flex justify-center pt-4">
        <button
          onClick={() => {
            logout()
          }}
          className="text-theme-purple text-lg md:text-base dark:brightness-125"
          data-testid="logoutButton"
        >
          Logout
        </button>
      </div>
    </div>
  )
}

interface NavLinkProps {
  to: string
  label: string
}

function NavLink(props: NavLinkProps) {
  const { to, label } = props

  return (
    <Popover.Button
      as={Link}
      to={to}
      className="dark:text-theme-body-white inline-block whitespace-nowrap py-0.5 text-black"
    >
      {label}
    </Popover.Button>
  )
}
