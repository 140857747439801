import { atom, useAtom } from 'jotai'
import { Wallet } from '@canonic/react'

const paymailAtom = atom<string | null>(null)

export default function usePaymail() {
  const [paymail, setPaymail] = useAtom(paymailAtom)

  const refreshPaymail = async () => {
    try {
      const u = await Wallet.getUser()
      if (u) {
        setPaymail(u.paymail)
      }
    } catch (err) {
      console.error(err)
    }
  }

  return {
    paymail,
    refreshPaymail,
  }
}
