import { SseData, SseEvent } from '@canonic/lib'
import { Env } from 'lib/env'
import { useEffect, useState } from 'react'

export default function useSse<T extends SseEvent>(
  event: T,
  onEvent: (data: SseData<T>) => void,
) {
  const [sse, setSse] = useState<EventSource>()

  useEffect(() => {
    const source = new EventSource(
      `${Env.DEV ? `http://localhost:4000` : ''}/api/sse`,
    )
    setSse(source)

    return () => {
      if (sse) {
        sse.onmessage = null
        sse.close()
      }
    }
  }, [])

  useEffect(() => {
    if (sse) {
      sse.addEventListener(event, ({ data }) => {
        onEvent(JSON.parse(data))
      })
    }
  }, [onEvent, event, sse])
}

export function useUserSse(
  paymail: string = '',
  onEvent: (data: SseData<`NOTIFICATION-${string}`>) => void,
) {
  const [sse, setSse] = useState<EventSource>()
  const event = `NOTIFICATION-${paymail}`

  useEffect(() => {
    if (paymail) {
      const source = new EventSource(
        `${Env.DEV ? `http://localhost:4000` : ''}/api/sse/${paymail}`,
      )
      setSse(source)

      return () => {
        if (sse) {
          sse.onmessage = null
          sse.close()
        }
      }
    }
  }, [paymail])

  useEffect(() => {
    const listener = ({ data }: any) => {
      onEvent(JSON.parse(data))
    }

    if (sse) {
      sse.addEventListener(event, listener)
    }

    return () => {
      sse?.removeEventListener(event, listener)
    }
  }, [onEvent, sse, event])
}

export function useGuestCheckoutSse<
  T extends Extract<SseEvent, `GUEST_CHECKOUT_SUCCESS-${string}`>,
>(guestPubkey: string | null = '', onEvent: (data: SseData<T>) => void) {
  const [sse, setSse] = useState<EventSource>()
  const event: SseEvent = `GUEST_CHECKOUT_SUCCESS-${guestPubkey}`

  useEffect(() => {
    if (guestPubkey) {
      const source = new EventSource(
        `${
          Env.DEV ? `http://localhost:4000` : ''
        }/api/sse/guest-checkout-session/${guestPubkey}`,
      )
      setSse(source)

      return () => {
        if (sse) {
          sse.onmessage = null
          sse.close()
        }
      }
    }
  }, [guestPubkey])

  useEffect(() => {
    const listener = ({ data }: any) => {
      onEvent(JSON.parse(data))
    }

    if (sse) {
      sse.addEventListener(event, listener)
    }

    return () => {
      sse?.removeEventListener(event, listener)
    }
  }, [onEvent, sse, event])
}
