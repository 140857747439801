import Footer from './Footer'
import Navbar from './Navbar/Navbar'
import cx from 'classnames'
import { useLoggingIn } from 'hooks/hooks'
import { LoadingIndicator, LoadingView } from '@canonic/react'
import { Outlet } from 'react-router-dom'
import { Suspense, useEffect, useState } from 'react'

export default function MainLayout() {
  const [loggingIn] = useLoggingIn()

  const [height, setHeight] = useState(0)

  useEffect(() => {
    window.addEventListener('resize', (e) => {
      const windowHeight = window.innerHeight
      setHeight(windowHeight)
    })
    window.addEventListener('load', (e) => {
      const windowHeight = window.innerHeight
      setHeight(windowHeight)
    })
  }, [])

  return (
    <>
      {/* Global loading indicator */}
      <div
        style={{ height: `${height}px` }}
        className={`bg-theme-body-white pointer-events-none fixed z-40 flex min-h-screen w-screen items-center justify-center overflow-hidden overflow-y-hidden bg-opacity-50 dark:bg-gray-800 ${cx(
          { hidden: !loggingIn, block: loggingIn },
        )} }`}
      >
        <LoadingIndicator />
      </div>
      <div
        style={{ height: `${height}px` }}
        className="selection:bg-theme-purple flex min-h-screen flex-col selection:text-white"
      >
        <Navbar />
        <div className="flex flex-1 flex-col">
          <Suspense fallback={<LoadingView />}>
            <Outlet />
          </Suspense>
        </div>
        <Footer />
      </div>
      <div
        style={{ height: `${height + 200}px` }}
        className="pointer-events-none fixed inset-0 z-[9999] hidden min-h-screen md:dark:block"
      >
        <div className="bg-theme-purple/20 absolute bottom-[calc(-11%+200px)] right-[-10%] h-[450px] w-[450px] scale-150 rounded-full blur-3xl" />
      </div>
    </>
  )
}
