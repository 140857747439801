'use client'
import { Switch } from '@headlessui/react'

interface Props {
  enabled: boolean
  setEnabled: (enabled: boolean) => void
  /** Screen-reader only label */
  srLabel: string
}

export function ToggleButton(props: Props) {
  const { enabled, setEnabled, srLabel } = props

  return (
    <Switch
      name=""
      checked={enabled}
      onChange={setEnabled}
      className={`${
        enabled ? 'bg-theme-purple' : 'bg-gray-200 dark:bg-gray-700'
      } relative inline-flex h-7 w-12 items-center rounded-full border border-gray-300 shadow-inner dark:border-none`}
    >
      <span className="sr-only">{srLabel}</span>
      <span
        className={`${
          enabled
            ? 'border-theme-purple translate-x-6'
            : 'translate-x-0 border-gray-200 dark:border-gray-700'
        } bg-theme-body-white inline-block h-7 w-7 transform rounded-full border-2 transition`}
      />
    </Switch>
  )
}
