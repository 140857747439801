import { ProductUnavailableReason } from '@canonic/lib'
import { Env } from 'backend/src/lib/env'

export type WALLET_PROVIDERS = 'Canonic' | 'Money Button' | 'RelayX'

export const MIME_TYPE = {
  EPUB: 'application/epub+zip',
  PDF: 'application/pdf',
  TEXT: 'text/plain',
  MOBI: 'application/x-mobipocket-ebook',
  MP3: 'audio/mpeg',
  JSON: 'application/json',
  MP4: 'video/mp4',
  MKV: 'video/x-matroska',
} as const

export const VANITY_FILE_TYPES: Record<string, string> = {
  'application/epub+zip': 'ePub',
  'application/pdf': 'PDF',
  'text/plain': 'Text',
  'application/x-mobipocket-ebook': 'Mobipocket',
  'audio/mpeg': 'Audiobook',
  'application/json': 'JSON',
  'video/mp4': 'Video',
  'video/x-matroska': 'Video',
}

export const BOOK_TYPES = {
  NFT: 'NFTs',
  EBOOK: 'eBooks',
}

const megabyte = 1_000_000
export const MAX_COVER_IMAGE_SIZE_BYTES = 10 * megabyte
export const VALID_EMAIL_REGEX = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
)

export const RELAYX_ENCRYPTION_PREFIX = Env.DEV
  ? '1RELAYTEST'
  : '1AYfdaE4yM1KFmE1BE4KEwZDNvq9URfjta'

export const ONE_BOOKS_PUBKEY =
  '02f787e665d2fdd6eff6b86491a3275ba3d47fa13a4ad010d7fe2e3d00c690cd5a'

export const PAYMAIL_NFT_RECEIPTS = '347h13wp5elzw7jsqyxuyojhs3jj@canonic.xyz' // canonic-nft@canonic.xyz paymail

export const ANALYTICS = {
  /** All normal page views are tracked automatically.
   * The below views should only be used for modals/views that ARE NOT tied to a URL.
   */
  VIEWS: {
    LOGIN: 'login',
    SIGNUP: 'signup',
  },
  EVENTS: {
    ADD_TO_CART: 'Add to cart',
    SUBMIT_LOGIN: 'Login',
    SUBMIT_SIGNUP: 'Signup',
    CLICK_GET_STARTED: 'Click get started',
    CLICK_EXPLORE_COLLECTIBLES: 'Click explore collectibles',
    CLICK_PUBLISH_CTA: 'Click publish CTA',
    CLICK_CHECKOUT: 'Click checkout',
    ADMIN_SAVE_PRODUCT: 'Admin save product',
    PURCHASE_SUCCESS: 'Checkout success',
    BUY_NOW: 'Buy now',
    COPY_REFERRAL_LINK: 'Copy referral link',
  },
} as const

export const ASPECT_RATIO_CLASSES = {
  SQUARE: 'aspect-square',
  BOOK: 'aspect-[6/9]',
  MOVIE: `aspect-[16/9]`,
} as const

export const LOCAL_STORAGE = {
  GUEST_CART_PUBKEY: 'guestCartPubkey',
  REFERRER_PAYMAIL: 'referrerPaymail',
} as const

export const SEARCH_PARAMS = {
  REFERRER_PAYMAIL: 'referrerPaymail',
  ORDINAL_SCREENSHOT: 'ordinalScreenshot',
  HASH: 'hash',
}

export const PAY_BUTTON_SCRIPT_ID = `canonic-pay-script`
export const PAY_BUTTON_STYLE_SRC = Env.DEV
  ? 'http://localhost:3003/dist/canonic-pay.min.css?q=12312234211112'
  : 'https://pay.canonic.xyz/dist/canonic-pay.min.css?q=12312234211112'
export const PAY_BUTTON_SCRIPT_SRC = Env.DEV
  ? 'http://localhost:3003/dist/canonic-pay.min.js?q=12312234211112'
  : 'https://pay.canonic.xyz/dist/canonic-pay.min.js?q=12312234211112'
export const PAY_BUTTON_CALLBACK_URL = Env.DEV
  ? 'http://localhost:4000/api/checkout/pay-button-callback'
  : 'https://canonic.xyz/api/checkout/pay-button-callback'

export const FEATURE_FLAGS = {
  RUNES: 'runes',
}
