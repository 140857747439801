'use client'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { ToastContainer } from 'react-toastify'

/** Styled `ToastContainer` */
export function Toast() {
  return (
    <ToastContainer
      position="bottom-center"
      hideProgressBar={true}
      closeOnClick={false}
      toastClassName="!rounded-lg !bg-white/80 dark:!bg-gray-700/80 !backdrop-blur-lg !mx-3 !sm:mx-0"
      pauseOnFocusLoss={false}
      closeButton={({ closeToast }) => (
        <button
          onClick={closeToast}
          className="dark:text-theme-body-white flex h-4 w-4 flex-shrink-0 items-center justify-center text-black"
        >
          <XMarkIcon />
        </button>
      )}
      bodyClassName="font-light text-black dark:text-theme-body-white"
    />
  )
}
