import React from 'react'
import { SelectBox } from './SelectBox'
import { COUNTRY_CODES } from '@canonic/lib'

type Props = React.SelectHTMLAttributes<HTMLSelectElement>

export const CountrySelectBox = React.forwardRef<HTMLSelectElement, Props>(
  (props, ref) => {
    return (
      <SelectBox {...props} ref={ref}>
        <option value="" disabled={true}>
          Select...
        </option>

        {Object.values(COUNTRY_CODES).map((country) => (
          <option key={country} value={country}>
            {country}
          </option>
        ))}
      </SelectBox>
    )
  },
)
