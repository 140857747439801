import { IUser } from '@canonic/lib'

import { Popover, Transition } from '@headlessui/react'
import ProfileMenu from './ProfileMenu'
import useProfileImage from 'hooks/useProfileImage'

interface Props {
  user: IUser
  unreadNotifications?: number
}

export default function ProfileDropdown(props: Props) {
  const { unreadNotifications } = props
  const { profileImage } = useProfileImage()

  return (
    <Popover
      className={'relative flex items-center'}
      data-testid="profileButton"
    >
      <Popover.Button className="hover:border-theme-purple relative h-12 w-12 flex-shrink-0 rounded-full border-2 border-transparent bg-gray-200 transition-colors focus:outline-none dark:bg-gray-700">
        {profileImage ? (
          <img
            alt=""
            className="h-full w-full rounded-full"
            src={profileImage}
          />
        ) : (
          <div className="h-24 w-24 rounded-full" />
        )}
        {!!unreadNotifications && (
          <div className="bg-theme-purple absolute right-0 top-0 -mr-1 -mt-0.5 flex h-4 w-4 items-center justify-center rounded-full text-xs text-white">
            {unreadNotifications}
          </div>
        )}
      </Popover.Button>
      <Transition
        className="text-theme-body-gray absolute right-0 top-14 flex origin-top flex-col divide-y overflow-hidden rounded-lg bg-white/80 pb-4 text-lg shadow-xl backdrop-blur-lg transition-all ease-in-out dark:bg-gray-700/80 dark:text-gray-300 dark:shadow-gray-900"
        enterFrom="-translate-y-3 opacity-0"
        enterTo="translate-y-none opacity-100"
        leaveFrom="translate-y-none opacity-100"
        leaveTo="-translate-y-3 opacity-0"
      >
        <Popover.Panel>
          <ProfileMenu unreadNotifications={unreadNotifications} />
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
