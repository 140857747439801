import { lazy } from 'react'
import {
  Navigate,
  Outlet,
  Route,
  Routes as ReactRouterRoutes,
  useParams,
} from 'react-router-dom'
import { api, hasAPIToken } from '../lib/api'
import { LoadingView } from '@canonic/react'

// Don't lazy load layouts if they are lightweight (better UX)
import NotFoundPage from 'pages/404Page'
import MainLayout from './MainLayout'
import ProfileLayout from 'pages/Profile/components/ProfileLayout'
import AdminLayout from 'pages/Admin/layouts/AdminLayout'

const ProductFilePage = lazy(
  () => import('../pages/ProductFile/ProductFilePage'),
)
const FaqPage = lazy(() => import('../pages/FAQ/FaqPage'))
const HomePage = lazy(() => import('../pages/Home/HomePage'))
const PrivacyPolicyPage = lazy(() => import('../pages/PrivacyPolicyPage'))
const TermsOfUsePage = lazy(() => import('../pages/TermsOfUsePage'))
const MarketplacePage = lazy(
  () => import('../pages/Marketplace/MarketplacePage'),
)
const ProfileOrdersPage = lazy(
  () => import('../pages/Profile/subpages/OrdersPage'),
)
const ProfilePurchasesCategoryPage = lazy(
  () => import('../pages/Profile/subpages/PurchasesByCategoryPage'),
)
const ProfileOrdinalsPage = lazy(
  () => import('../pages/Profile/subpages/OrdinalsPage'),
)
const ProfileTransactionsPage = lazy(
  () => import('../pages/Profile/subpages/TransactionsPage'),
)
const ProfileCollectiblesPage = lazy(
  () => import('../pages/Profile/subpages/CollectiblesPage'),
)
const ProfileWalletPage = lazy(
  () => import('pages/Profile/subpages/WalletPage'),
)
const ProfileReferralsPage = lazy(
  () => import('pages/Profile/subpages/ReferralsPage'),
)
const ProfileRunesPage = lazy(() => import('pages/Profile/subpages/RunesPage'))
const RequestPublishingPage = lazy(
  () => import('pages/RequestPaperbackPublishingPage'),
)
const TokenPage = lazy(() => import('../pages/Token/TokenPage'))
const RecoverPage = lazy(() => import('../pages/RecoverPage'))
const ExplorePage = lazy(() => import('pages/ExplorePage'))
const FundWalletPage = lazy(() => import('pages/FundWalletPage'))
const DashboardPage = lazy(() => import('pages/DashboardPage'))
const GutenbergPage = lazy(() => import('pages/Gutenberg/GutenbergPage'))
const GutenbergExplorePage = lazy(
  () => import('pages/Gutenberg/GutenbergExplorePage'),
)

// Admin
const StoreDetailsPage = lazy(
  () => import('pages/Admin/subpages/StoreDetailsPage'),
)
const AdminSettingsPage = lazy(
  () => import('pages/Admin/subpages/SettingsPage'),
)
const ShippingProfilesPage = lazy(
  () => import('pages/Admin/subpages/ShippingProfilesPage'),
)
const EditShippingProfilePage = lazy(
  () => import('pages/Admin/subpages/EditShippingProfilePage'),
)
const AdminProductListPage = lazy(
  () => import('pages/Admin/subpages/ProductListPage'),
)
const EditProductPage = lazy(
  () => import('pages/Admin/subpages/EditProductPage'),
)
const NewProductPage = lazy(() => import('pages/Admin/subpages/NewProductPage'))
const ProductPage = lazy(() => import('pages/Product/ProductPage'))
const CartPage = lazy(() => import('pages/Cart/CartPage'))
const CustomerListPage = lazy(
  () => import('pages/Admin/subpages/CustomerListPage'),
)
const CustomerPage = lazy(() => import('pages/Admin/subpages/CustomerPage'))
const OrderListPage = lazy(() => import('pages/Admin/subpages/OrderListPage'))
const OrderPage = lazy(() => import('pages/Admin/subpages/OrderPage'))
const StorePage = lazy(() => import('pages/StorePage'))

const ROUTES = {
  FAQ: '/faq',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_OF_USE: '/terms-of-use',
  COLLECTIBLES: '/collectibles',
  COLLECTIBLES_SOLD: '/collectibles/sold',
  /** @deprecated Use `buildTokenRoute` when navigating instead of this constant */
  TOKEN: '/collectibles/:tokenId',
  PRODUCT_FILE: '/p/:productAddress/files/:fileTxid',
  PROFILE: {
    BASE: '/profile',
    PURCHASES: {
      ORDERS: '/profile/orders',
      /** @deprecated Use `buildPurchasesRoutes` when navigating instead of this constant */
      CATEGORY: '/profile/purchases/:category',
      ORDINALS: '/profile/purchases/ordinals',
    },
    REFERRALS: `/profile/referrals`,
    COLLECTIBLES: `/profile/collectibles`,
    HISTORY: `/profile/history`,
    WALLET: `/profile/wallet`,
    RESTORE_PURCHASES: '/profile/restore-purchases',
    SEED_PHRASE: '/profile/seed-phrase',
    RUNES: '/profile/runes',
  },
  REQUEST_PAPERBACK_PUBLISHING: '/request-paperback-publishing',
  RECOVER: '/recover',
  HOME: '/',
  /** @deprecated Use `buildExploreRoute` when navigating instead of this constant */
  EXPLORE: '/explore/:category',
  FUND_WALLET: '/fund-wallet',
  DASHBOARD: '/dashboard',
  GUTENBERG: '/gutenberg',
  GUTENBERG_EXPLORE: '/gutenberg/explore',
  ADMIN: {
    BASE: '/admin',
    SETTINGS: '/admin/settings',
    PRODUCTS: '/admin/products',
    /** @deprecated Use `buildEditProductRoute` helper instead */
    EDIT_PRODUCT: '/admin/products/:address',
    NEW_PRODUCT: '/admin/products/new',
    STORE_DETAILS: '/admin/settings/store-details',
    SHIPPING_PROFILES: '/admin/settings/shipping-profiles',
    /** @deprecated Use `buildEditShippingProfileRoute` helper instead */
    EDIT_SHIPPING_PROFILE: '/admin/settings/shipping-profiles/:address',
    NEW_SHIPPING_PROFILE: '/admin/settings/shipping-profiles/new',
    CUSTOMERS: '/admin/customers',
    /** @deprecated Use `buildCustomerRoute` helper instead */
    CUSTOMER: '/admin/customers/:pubkey',
    ORDERS: '/admin/orders',
    /** @deprecated Use `buildOrderRoute` helper instead */
    ORDER: '/admin/orders/:id',
  },
  /** @deprecated Use `buildProductRoute` helper instead */
  PRODUCT: '/p/:address',
  CART: '/cart',
  /** @deprecated Use `buildStoreRoute` helper instead */
  STORE: '/store/:address',

  // Keep so legacy links don't break
  /** @deprecated Use `buildProductRoute` instead */
  BOOK: '/books/:address',
  /** @deprecated Use `buildProductRoute` instead */
  BOOK_EDITION: '/books/:address/edition/:edition',
  /** @deprecated Use `buildProductRoute` instead */
  BOOK_TYPE: '/books/:address/:type',
  /** @deprecated Use `buildProductFileRoute` when navigating instead of this constant */
} as const

const buildTokenRoute = (tokenId: string) =>
  ROUTES.TOKEN.replace(':tokenId', tokenId)
const buildProductFileRoute = (productAddress: string, fileTxid: string) =>
  ROUTES.PRODUCT_FILE.replace(':productAddress', productAddress).replace(
    ':fileTxid',
    fileTxid,
  )
const buildEditShippingProfileRoute = (address: string) =>
  ROUTES.ADMIN.EDIT_SHIPPING_PROFILE.replace(':address', address)
const buildEditProductRoute = (address: string) =>
  ROUTES.ADMIN.EDIT_PRODUCT.replace(':address', address)
const buildProductRoute = (address: string) =>
  ROUTES.PRODUCT.replace(':address', address)
const buildCustomerRoute = (pubkey: string) =>
  ROUTES.ADMIN.CUSTOMER.replace(':pubkey', pubkey)
const buildOrderRoute = (id: string) => ROUTES.ADMIN.ORDER.replace(':id', id)
const buildExploreRoute = (category: string) =>
  ROUTES.EXPLORE.replace(':category', category.toLowerCase())
const buildStoreRoute = (address: string) =>
  ROUTES.STORE.replace(':address', address)
const buildPurchasesRoutes = (category: string) =>
  ROUTES.PROFILE.PURCHASES.CATEGORY.replace(':category', category.toLowerCase())

const Routes = Object.assign(
  {
    buildTokenRoute,
    buildProductFileRoute,
    buildEditShippingProfileRoute,
    buildEditProductRoute,
    buildProductRoute,
    buildCustomerRoute,
    buildOrderRoute,
    buildExploreRoute,
    buildStoreRoute,
    buildPurchasesRoutes,
  },
  ROUTES,
)

function ProtectedLayout() {
  const { data: user, isLoading } = api.user.get.useQuery(undefined, {
    enabled: hasAPIToken(),
  })

  if (isLoading) return <LoadingView />
  if (!user) return <Navigate to={Routes.HOME} replace />

  return <Outlet />
}

function LegacyBookRedirect() {
  const { address } = useParams<{ address?: string }>()
  if (!address) throw Error('Missing product address')

  return <Navigate to={buildProductRoute(address)} replace />
}

const AppRoutes = () => (
  <ReactRouterRoutes>
    <Route element={<MainLayout />}>
      <Route path={ROUTES.FAQ} element={<FaqPage />} />
      <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicyPage />} />
      <Route path={ROUTES.TERMS_OF_USE} element={<TermsOfUsePage />} />

      <Route path={ROUTES.COLLECTIBLES} element={<MarketplacePage />} />
      <Route path={ROUTES.COLLECTIBLES_SOLD} element={<MarketplacePage />} />
      <Route path={ROUTES.TOKEN} element={<TokenPage />} />
      <Route path={ROUTES.RECOVER} element={<RecoverPage />} />
      <Route path={ROUTES.HOME} element={<HomePage />} />
      <Route path={ROUTES.EXPLORE} element={<ExplorePage />} />
      <Route path={ROUTES.GUTENBERG} element={<GutenbergPage />} />
      <Route
        path={ROUTES.GUTENBERG_EXPLORE}
        element={<GutenbergExplorePage />}
      />
      <Route path={ROUTES.PRODUCT} element={<ProductPage />} />
      <Route path={ROUTES.PRODUCT_FILE} element={<ProductFilePage />} />
      <Route path={ROUTES.STORE} element={<StorePage />} />

      <Route path={ROUTES.CART} element={<CartPage />} />

      {/* Legacy redirects */}
      <Route path={ROUTES.BOOK} element={<LegacyBookRedirect />} />
      <Route path={ROUTES.BOOK_EDITION} element={<LegacyBookRedirect />} />
      <Route path={ROUTES.BOOK_TYPE} element={<LegacyBookRedirect />} />

      <Route element={<ProtectedLayout />}>
        <Route
          path={ROUTES.REQUEST_PAPERBACK_PUBLISHING}
          element={<RequestPublishingPage />}
        />
        <Route path={ROUTES.FUND_WALLET} element={<FundWalletPage />} />
        <Route path={ROUTES.DASHBOARD} element={<DashboardPage />} />

        {/* Profile */}
        <Route element={<ProfileLayout />}>
          <Route
            path={ROUTES.PROFILE.PURCHASES.ORDERS}
            element={<ProfileOrdersPage />}
          />
          <Route
            path={ROUTES.PROFILE.PURCHASES.CATEGORY}
            element={<ProfilePurchasesCategoryPage />}
          />
          <Route
            path={ROUTES.PROFILE.PURCHASES.ORDINALS}
            element={<ProfileOrdinalsPage />}
          />
          <Route
            path={ROUTES.PROFILE.HISTORY}
            element={<ProfileTransactionsPage />}
          />
          <Route
            path={ROUTES.PROFILE.COLLECTIBLES}
            element={<ProfileCollectiblesPage />}
          />
          <Route path={ROUTES.PROFILE.WALLET} element={<ProfileWalletPage />} />
          <Route
            path={ROUTES.PROFILE.RESTORE_PURCHASES}
            element={<ProfileWalletPage />}
          />
          <Route
            path={ROUTES.PROFILE.SEED_PHRASE}
            element={<ProfileWalletPage />}
          />
          <Route
            path={ROUTES.PROFILE.REFERRALS}
            element={<ProfileReferralsPage />}
          />
          <Route path={ROUTES.PROFILE.RUNES} element={<ProfileRunesPage />} />

          {/* Make sure this is the last profile page */}
          <Route
            path={ROUTES.PROFILE.BASE}
            element={<Navigate to={ROUTES.PROFILE.PURCHASES.ORDERS} />}
          />
        </Route>
      </Route>
    </Route>

    {/* Admin */}
    <Route element={<AdminLayout />}>
      <Route element={<ProtectedLayout />}>
        <Route path={ROUTES.ADMIN.SETTINGS} element={<AdminSettingsPage />} />
        <Route
          path={ROUTES.ADMIN.STORE_DETAILS}
          element={<StoreDetailsPage />}
        />

        {/* Shipping Profiles */}
        <Route
          path={ROUTES.ADMIN.SHIPPING_PROFILES}
          element={<ShippingProfilesPage />}
        />
        <Route
          path={ROUTES.ADMIN.EDIT_SHIPPING_PROFILE}
          element={<EditShippingProfilePage />}
        />
        <Route
          path={ROUTES.ADMIN.NEW_SHIPPING_PROFILE}
          element={<EditShippingProfilePage />}
        />

        {/* Products */}
        <Route
          path={ROUTES.ADMIN.PRODUCTS}
          element={<AdminProductListPage />}
        />
        <Route path={ROUTES.ADMIN.NEW_PRODUCT} element={<NewProductPage />} />
        <Route path={ROUTES.ADMIN.EDIT_PRODUCT} element={<EditProductPage />} />

        {/* Customers */}
        <Route path={ROUTES.ADMIN.CUSTOMERS} element={<CustomerListPage />} />
        <Route path={ROUTES.ADMIN.CUSTOMER} element={<CustomerPage />} />

        {/* Orders */}
        <Route path={ROUTES.ADMIN.ORDERS} element={<OrderListPage />} />
        <Route path={ROUTES.ADMIN.ORDER} element={<OrderPage />} />

        {/* Fallback Redirect */}
        <Route
          path={ROUTES.ADMIN.BASE}
          element={<Navigate to={ROUTES.ADMIN.PRODUCTS} />}
        />
      </Route>
    </Route>

    <Route path="*" element={<NotFoundPage />} />
  </ReactRouterRoutes>
)

export { Routes, AppRoutes }
