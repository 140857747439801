export const Env = {
  DEV: !!import.meta.env.DEV,
  BETA: !!import.meta.env.VITE_BETA,
  PROD: !!(import.meta.env.PROD && !import.meta.env.VITE_BETA),
  get SENTRY_ENABLED() {
    return this.PROD || this.BETA
  },
  get SIGNET_ENABLED() {
    return !this.PROD
  },
}
