import { numberWithCommas } from '@canonic/lib'
import { formatDistance } from 'date-fns'
import { format } from 'date-fns'
import { Env } from './env'

export const timeAgo = (date: number) => {
  return formatDistance(date, new Date(), { addSuffix: true })
}

export const formattedFileSize = (bytes = 0) => {
  const kb = parseInt((bytes / 1000).toFixed())
  const mb = parseInt((kb / 1000).toFixed())

  return kb === 0
    ? '< 1 KB'
    : mb > 1
      ? `${numberWithCommas(mb)} MB`
      : `${numberWithCommas(kb)} KB`
}

export const getOrdinal = (integer: number) => {
  var j = integer % 10,
    k = integer % 100
  if (j === 1 && k !== 11) {
    return integer + 'st'
  }
  if (j === 2 && k !== 12) {
    return integer + 'nd'
  }
  if (j === 3 && k !== 13) {
    return integer + 'rd'
  }
  return integer + 'th'
}

export const formatDate = (date: number) => format(date, 'MMMM do, yyyy')

export const getOrdinalExplorerUrl = (ordinalId: string) => {
  return `${Env.PROD ? 'https://ordiscan.com' : 'https://signet.ordinals.com'}/inscription/${ordinalId}`
}
