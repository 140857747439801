import * as Sentry from '@sentry/react'
import React from 'react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import { CaptureConsole, captureConsoleIntegration } from '@sentry/integrations'
import { Env } from 'lib/env'

Sentry.init({
  dsn: 'https://42711831a4ffffddd85f3dae8ce51127@o4506536576155648.ingest.sentry.io/4506536601124864',
  // Comment out this line if you need to debug locally
  enabled: Env.SENTRY_ENABLED,
  ignoreErrors: ['TypeError: Load failed'],
  integrations: [
    captureConsoleIntegration({ levels: ['error'] }),
    Sentry.feedbackIntegration({
      showBranding: false,
      autoInject: false,
    }),

    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 0.5,
  environment: Env.DEV ? 'development' : Env.BETA ? 'beta' : 'production',
})
