import { useEffect, useState } from 'react'

export function useOffline() {
  const [offline, setOffline] = useState(!navigator.onLine)

  useEffect(() => {
    const onOffline = () => setOffline(true)
    window.addEventListener('offline', onOffline)
    const onOnline = () => setOffline(false)
    window.addEventListener('online', onOnline)

    return () => {
      window.removeEventListener('offline', onOffline)
      window.removeEventListener('online', onOnline)
    }
  }, [])

  return offline
}
