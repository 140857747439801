import { twMerge } from 'tailwind-merge'

interface Props {
  className?: string
}

export default function Spinner(props: Props) {
  const defaultClassName = 'h-8 w-8 text-theme-purple block'

  return (
    <span className={twMerge(defaultClassName, props.className)}>
      <span
        className={`box-border inline-block h-full w-full animate-spin rounded-full border-2 border-current border-b-transparent`}
      />
    </span>
  )
}
