import { Ticker } from '@canonic/types'
import { Tooltip } from './Tooltip'

export function ViewOnChain({
  txid,
  ticker = 'BSV',
}: {
  txid: string
  ticker?: Ticker
}) {
  return (
    <Tooltip title="View on chain">
      <span>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={
            ticker === 'BSV'
              ? `https://whatsonchain.com/tx/${txid}`
              : `https://blockchair.com/search?q=${txid}`
          }
        >
          <Icon className="w-7" />
        </a>
      </span>
    </Tooltip>
  )
}

const Icon = ({ className }: { className?: string }) => (
  <svg
    width="34"
    height="10"
    viewBox="0 0 34 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0)">
      <path
        d="M5.57439 9.99111C4.97059 10.0217 4.36688 9.92908 3.79805 9.71866C3.22923 9.50823 2.70653 9.18414 2.2601 8.76506C1.81367 8.34598 1.45233 7.8402 1.1969 7.27687C0.941474 6.71355 0.797016 6.10382 0.771854 5.48283C0.661648 4.61368 0.780624 3.73005 1.11634 2.92437C1.45206 2.11869 1.99218 1.42056 2.6802 0.902993C3.55149 0.241322 4.62674 -0.0727568 5.70625 0.0190897C5.97913 0.0438269 6.23373 0.170547 6.42185 0.37527C6.60999 0.579993 6.7186 0.848515 6.72714 1.13002C6.74989 1.42188 6.665 1.71185 6.48933 1.9423C6.31365 2.17275 6.06007 2.3268 5.77898 2.37383C5.49485 2.41634 5.20339 2.40827 4.9224 2.47282C4.33502 2.60727 3.81872 2.96488 3.47688 3.47405C3.13505 3.98322 2.99291 4.60635 3.07893 5.21868C3.21393 6.76376 4.13698 7.59494 5.64607 7.59602C5.99195 7.59602 6.33783 7.59064 6.68371 7.59602C6.91918 7.60032 7.01598 7.48842 7.01389 7.25117C7.00866 6.69418 7.00866 6.13719 7.01389 5.5802C7.00707 5.41974 7.0323 5.25954 7.08802 5.10948C7.14373 4.95942 7.22875 4.82269 7.33784 4.70771C7.44693 4.59273 7.57777 4.50195 7.72229 4.44095C7.86681 4.37996 8.02196 4.35005 8.17816 4.35307C8.33418 4.35091 8.48901 4.38119 8.63336 4.44209C8.77772 4.50299 8.90861 4.59325 9.01818 4.70747C9.12775 4.82168 9.21375 4.95748 9.27099 5.10671C9.32824 5.25595 9.35556 5.41554 9.35132 5.5759C9.35969 6.10904 9.36545 6.64326 9.34818 7.17586C9.33876 7.4782 9.43138 7.62669 9.74063 7.59441C9.97536 7.55676 10.2156 7.59958 10.4243 7.71625C10.633 7.83292 10.7986 8.01697 10.8955 8.23998C11.0779 8.54148 11.2935 8.82038 11.538 9.07117C11.7008 9.25623 11.8431 9.43161 11.7447 9.69738C11.6364 9.99111 11.3957 9.99273 11.1435 9.99111C10.1399 9.98842 6.42783 9.99111 5.57439 9.99111Z"
        fill="#7E8D9F"
      />
      <path
        d="M15.222 9.99018C14.0985 9.981 13.0169 9.55089 12.1798 8.78047C11.3427 8.01006 10.8076 6.95222 10.6748 5.80522C10.5814 5.05259 10.6279 4.28855 10.8119 3.55377C10.8203 3.48355 10.8421 3.41572 10.8761 3.35418C10.91 3.29263 10.9554 3.23857 11.0097 3.19509C11.064 3.15162 11.1261 3.11958 11.1925 3.10081C11.2588 3.08205 11.3281 3.07692 11.3964 3.08573C11.611 3.08187 11.824 3.12361 12.0221 3.20833C12.2202 3.29305 12.3992 3.41893 12.5478 3.57807C12.6964 3.73722 12.8114 3.9262 12.8857 4.13318C12.9599 4.34016 12.9918 4.56067 12.9793 4.78091C12.8757 5.39841 13.012 6.03304 13.3588 6.54834C13.7056 7.06363 14.2352 7.41844 14.8337 7.53645C15.037 7.5776 15.2441 7.59564 15.4512 7.59025C17.2038 7.59419 18.9566 7.59419 20.7095 7.59025C21.06 7.60628 21.4096 7.54325 21.7339 7.40558C22.0582 7.2679 22.3492 7.05892 22.5866 6.7933C22.8239 6.52768 23.0019 6.21187 23.1079 5.86803C23.2139 5.52419 23.2454 5.16066 23.2002 4.80297C23.1725 4.592 23.1885 4.37738 23.2473 4.17325C23.3062 3.96913 23.4064 3.78013 23.5414 3.61874C23.6764 3.45734 23.8432 3.3272 24.0307 3.2369C24.2182 3.14661 24.4222 3.0982 24.6293 3.09487C25.1369 3.04861 25.2201 3.10886 25.3519 3.62102C25.654 4.69953 25.6013 5.85041 25.202 6.89498C24.8027 7.93956 24.0792 8.81938 23.1437 9.39786C22.4776 9.7822 21.7272 9.98585 20.9633 9.98964C19.9963 9.9961 16.1676 9.99449 15.222 9.99018Z"
        fill="#7E8D9F"
      />
      <path
        d="M29.8906 0.0442391C30.5556 0.0771446 31.2039 0.2694 31.7838 0.605663C32.3637 0.941927 32.8592 1.41289 33.2306 1.98097C33.4036 2.2352 33.4739 2.54854 33.4266 2.85498C33.3794 3.16143 33.2183 3.43706 32.9773 3.62382C32.7363 3.81057 32.4342 3.89387 32.1347 3.85617C31.8351 3.81846 31.5615 3.6627 31.3714 3.42169C31.1239 3.08548 30.8002 2.81658 30.4284 2.63843C30.0567 2.46027 29.6482 2.37826 29.2386 2.39952C27.8554 2.39737 26.4724 2.39737 25.0896 2.39952C23.3414 2.39952 22.3231 3.50292 22.4482 5.30731C22.4747 5.58537 22.4189 5.86518 22.2882 6.11013C22.1575 6.35509 21.9579 6.55379 21.7156 6.68024L21.6863 6.69907C20.7967 7.19186 20.3687 6.99765 20.1845 5.99539C19.7429 3.5777 20.9647 0.77159 23.6815 0.146994C24.0641 0.0650181 24.4535 0.0215948 24.8442 0.01734C25.6035 0.000124583 28.9728 -0.0170908 29.8906 0.0442391Z"
        fill="#7E8D9F"
      />
      <path
        d="M24.9376 9.99417C24.7079 9.99417 24.485 9.9436 24.4504 9.70474C24.4358 9.50567 24.4874 9.3074 24.597 9.14255C24.8204 8.80039 25.1296 8.51365 25.3295 8.16073C25.4265 7.9724 25.5754 7.8177 25.7576 7.71606C25.9398 7.61442 26.1471 7.57035 26.3536 7.5894C27.3556 7.60177 28.3582 7.59316 29.3608 7.59316C29.7482 7.60856 30.1332 7.52537 30.4819 7.351C30.8305 7.17663 31.1318 6.91646 31.3591 6.59359C31.4481 6.46578 31.5611 6.35754 31.6913 6.27523C31.8216 6.19293 31.9665 6.13822 32.1177 6.11431C32.2688 6.0904 32.423 6.09779 32.5713 6.13602C32.7196 6.17426 32.859 6.24258 32.9812 6.33698C33.1057 6.42864 33.2113 6.54474 33.2918 6.67856C33.3724 6.81239 33.4264 6.96129 33.4508 7.11669C33.4751 7.27209 33.4692 7.4309 33.4336 7.58396C33.3979 7.73702 33.3331 7.88131 33.2429 8.00848C32.8413 8.6126 32.3035 9.10787 31.6755 9.45205C31.0474 9.79622 30.3477 9.97908 29.636 9.98503C28.8794 10.0168 25.7445 9.9861 24.9376 9.99417Z"
        fill="#7E8D9F"
      />
      <path
        d="M18.0903 0.0112146C18.9432 0.0112146 19.7961 0.0149805 20.6491 0.0112146C20.8835 0.0112146 21.0876 0.0429556 21.1912 0.292579C21.2948 0.542202 21.175 0.708977 21.0222 0.892966C20.7464 1.2249 20.4837 1.57082 20.2414 1.92966C20.1586 2.07235 20.0411 2.19046 19.9005 2.27242C19.7598 2.35437 19.6009 2.39736 19.4393 2.39716C18.5173 2.39716 17.5953 2.39716 16.6733 2.39716C16.5289 2.39718 16.3868 2.35996 16.26 2.2889C16.1332 2.21785 16.0258 2.11523 15.9475 1.99045C15.6655 1.6031 15.3573 1.23727 15.0941 0.834326C15.0406 0.758739 15.003 0.672577 14.9835 0.581278C14.964 0.489978 14.9632 0.395524 14.981 0.303877C15.0295 0.22486 15.0951 0.158474 15.1728 0.109934C15.2504 0.0613938 15.3379 0.0320219 15.4284 0.0241262C16.3149 -8.29725e-05 17.2028 0.0117526 18.0903 0.0112146Z"
        fill="#7E8D9F"
      />
      <path
        d="M12.5409 0.146735C12.1217 0.0630251 11.6962 0.0176573 11.2693 0.011164C10.9678 0.000790147 10.6743 0.111946 10.4514 0.320919C10.2285 0.529893 10.0939 0.820141 10.0762 1.12975C10.0585 1.43935 10.1592 1.74381 10.3568 1.97817C10.5544 2.21252 10.8332 2.35823 11.1338 2.3842C12.882 2.3842 13.8993 3.50051 13.7742 5.3049C13.7479 5.58294 13.8038 5.86265 13.9345 6.10756C14.0652 6.35247 14.2646 6.5512 14.5068 6.67783L14.5361 6.69666C15.4256 7.18945 15.8537 6.99524 16.0384 5.99298C16.4805 3.57744 15.2582 0.771332 12.5409 0.146735Z"
        fill="#7E8D9F"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="32.7198"
          height="10"
          fill="white"
          transform="translate(0.748047)"
        />
      </clipPath>
    </defs>
  </svg>
)
