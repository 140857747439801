import { RuneMap, RuneUtxo, Wallet } from '@canonic/react'
import { atom, useAtom } from 'jotai'

const runesAtom = atom<RuneMap>({})
const pendingRuneUtxosAtom = atom<RuneUtxo[]>([])

export default function useRunes() {
  const [runes, setRunes] = useAtom(runesAtom)
  const [pendingRuneUtxos, setPendingRuneUtxos] = useAtom(pendingRuneUtxosAtom)

  const refreshRunes = async () => {
    if (Wallet.isLoggedIn()) {
      try {
        const { runes, pendingRuneUtxos } = await Wallet.getRunes()

        setRunes(runes)
        setPendingRuneUtxos(pendingRuneUtxos)
      } catch (err) {
        console.error(err)
      }
    }
  }

  return {
    runes,
    pendingRuneUtxos,
    refreshRunes,
  }
}
