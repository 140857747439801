'use client'
import { SunIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { useLocalStorage } from 'react-use'

declare global {
  interface Window {
    determineTheme?: () => void
  }
}

type Theme = 'dark' | 'light'

interface Props {
  onThemeChange?: (theme: 'dark' | 'light' | null | undefined) => void
}

export function ThemeToggle(props: Props) {
  // Necessary for Next.js apps
  const [domLoaded, setDomLoaded] = useState(false)
  const { onThemeChange } = props

  const [theme, setTheme] = useLocalStorage<Theme | null>('theme')
  const updateTheme = (theme: string) => {
    if (theme === 'system') {
      setTheme(null)
      localStorage.removeItem('theme')
    } else {
      setTheme(theme as Theme)
    }

    if (window.determineTheme) {
      window.determineTheme()
    }
  }

  useEffect(() => {
    if (onThemeChange) {
      onThemeChange(theme)
    }
  }, [theme])

  useEffect(() => setDomLoaded(true), [])

  if (!domLoaded) return null

  return (
    <label
      htmlFor="themeToggle"
      className="relative flex items-center justify-start text-black dark:text-inherit"
    >
      <div className="pointer-events-none absolute inset-x-0 flex h-6 w-6 items-center justify-center">
        <SunIcon />
      </div>
      <select
        id="themeToggle"
        value={theme || 'system'}
        onChange={(e) => updateTheme(e.currentTarget.value)}
        className={`pointer-events-auto block w-0 max-w-0 cursor-pointer appearance-none overflow-hidden border-none bg-transparent bg-none px-3 py-1 focus-within:outline-none focus:outline-none focus-visible:outline-none active:outline-none`}
      >
        <option value={'system'}>System</option>
        <option value={'dark'}>Dark</option>
        <option value={'light'}>Light</option>
      </select>
    </label>
  )
}
