import { Buffer } from 'buffer'
// @ts-ignore
globalThis.Buffer = Buffer
import { createRoot } from 'react-dom/client'

import lazysizes from 'lazysizes'
import App from './App'
import './styles/styles.css'
import '@canonic/react/src/styles/base.css'
import './sentry'

lazysizes.cfg.preloadAfterLoad = true

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(<App />)
