import { atom, useAtom } from 'jotai'
import { useCallback } from 'react'

// Define each modal type separately so we can have
// type safety when constructing the `ModalData` and `Modal` types
type Login = 'login'
type Signup = 'signup'
type Welcome = 'welcome'
type Checkout = 'checkout'

// Define specific data types for each modal
type LoginDataType = { onLogin?: () => void }
type SignupDataType = { onLogin?: () => void }

type Modals = Login | Signup | Welcome | Checkout | null

type ModalData<T extends Modals> = T extends Login
  ? LoginDataType
  : T extends Signup
    ? SignupDataType
    : undefined

type Modal =
  | { name: Login; data?: LoginDataType }
  | { name: Signup; data?: SignupDataType }
  | { name: Welcome; data?: undefined }
  | { name: Checkout; data?: undefined }
  | { name: null; data?: undefined }

const modalAtom = atom<Modal>({ name: null, data: undefined })

export default function useModal() {
  const [modal, setModalInternal] = useAtom(modalAtom)

  const setModal = useCallback(
    <T extends Modals>(name: T, data?: ModalData<T>) => {
      setModalInternal({ name, data } as Modal)
    },
    [setModalInternal],
  )

  return { modal, setModal }
}
