import { useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { clearApiToken, hasAPIToken, setAPIToken } from 'lib/api'
import { MASTER_PATHS } from '@canonic/lib'
import moneybutton from 'lib/moneybutton'
import { Wallet } from '@canonic/react'
import useModal from 'hooks/useModal'
import useProfileImage from './useProfileImage'
import usePaymail from './usePaymail'
import useBalance from './useBalance'
import { useLoggingIn } from './hooks'
import { api } from 'lib/api'
import { useGuestCart } from './useGuestCart'
import { switchModals } from 'lib/modals'
import useRunes from './useRunes'
import { Env } from 'lib/env'

interface Options {
  skipModalCleanupOnLogin?: boolean
}

export default function useAuth(options: Options = {}) {
  const [, setLoggingIn] = useLoggingIn()
  const { refreshProfileImage } = useProfileImage()
  const { refreshPaymail } = usePaymail()
  const { refreshBalance } = useBalance()
  const { refreshRunes } = useRunes()
  const { modal, setModal } = useModal()
  const guestCart = useGuestCart()
  const queryClient = useQueryClient()

  const convertGuestCart = api.cart.convertFromGuestCart.useMutation()

  const navigate = useNavigate()

  const { data: user, refetch: refetchUser } = api.user.get.useQuery(
    undefined,
    {
      enabled: hasAPIToken(),
    },
  )

  const { refetch: refetchCart } = api.cart.get.useQuery({
    guestPubKey: guestCart.guestPubKey,
  })

  const loginMutation = api.authentication.login.useMutation({
    onSuccess: async (data, input) => {
      setAPIToken(data.token)

      await refetchUser()
      await refreshPaymail()
      await refreshProfileImage()

      setLoggingIn(false)

      const redirect = new URL(
        localStorage.getItem('canonic_login_redirect') || window.location.href,
      ).pathname

      navigate(redirect)

      if (guestCart.guestPubKey) {
        try {
          await convertGuestCart.mutateAsync({
            guestPubKey: guestCart.guestPubKey,
          })
          guestCart.removeGuestPubkey()
        } catch {}
      }
      toast.success(`Logged in: ${data.user.email}`)

      let cleanupFinalModal = true

      if (modal.name === 'login' && modal.data?.onLogin) {
        const onLogin = modal.data.onLogin
        switchModals(
          () => setModal(null),
          () => onLogin(),
        )
        cleanupFinalModal = false
      }

      if (modal.name === 'signup' && modal.data?.onLogin) {
        const onLogin = modal.data.onLogin
        switchModals(
          () => setModal(null),
          () => onLogin(),
        )
        cleanupFinalModal = false
      }

      if (input.comingFromSignup && cleanupFinalModal) {
        switchModals(
          () => setModal(null),
          () => setModal('welcome'),
        )
        cleanupFinalModal = false
      }

      cleanupFinalModal && !options.skipModalCleanupOnLogin && setModal(null)
    },
  })

  const convertToGuestCartMutation = api.cart.convertToGuestCart.useMutation({
    onSuccess: async (data, { guestPubKey }) => {
      // Update the state value here so we avoid any race conditions
      guestCart.setGuestPubkey(guestPubKey)

      await refetchCart()
    },
  })

  const login = async (comingFromSignup = false) => {
    const arkUser = await Wallet.getUser()

    if (!arkUser) throw Error('Not logged in')
    const { id, email, pubKey } = arkUser

    const msg = (+new Date()).toString()
    const sig = Wallet.sign(Buffer.from(msg), MASTER_PATHS.CANONIC) // TODO: Use wallet specific path instead of canonic!

    const payload = {
      auth: { sig, msg },
      user: {
        keys: {
          id: { paymail: id, pubkey: pubKey },
        },
        email,
      },
    }

    await loginMutation.mutateAsync({ ...payload, comingFromSignup })
  }

  const logout = () => {
    // Convert their cart to a guest cart so they don't lose their items
    const userPubKey = user?.keys.id.pubkey
    const guestPubKey = guestCart.getNewGuestPubKey()
    if (userPubKey) {
      convertToGuestCartMutation.mutateAsync({ userPubKey, guestPubKey })
    }

    Wallet.logout()
    moneybutton.logout()
    clearApiToken()

    queryClient.setQueriesData(
      {
        predicate(query) {
          return query.queryKey.some((k) =>
            Array.isArray(k) ? k.includes('user') : k === 'user',
          )
        },
      },
      null,
    )
  }

  const closeLogin = async () => {
    setModal(null)
    setLoggingIn(false)
  }

  const [sseConnected, setSseConnected] = useState(false)
  useEffect(() => {
    if (user && Wallet.isLoggedIn() && !sseConnected) {
      setSseConnected(true)
      Wallet.connectUserSse((obj: any) => {
        if (obj.onchange === 'user') {
          refreshPaymail()
          refreshProfileImage()
        }
        if (obj.onchange === 'wallet') {
          refreshBalance()
          refreshRunes()
        }
        if (obj.onchange === 'price') refreshBalance()
      })

      Wallet.connectWalletSse((obj) => {
        refreshBalance()
        refreshRunes()
      }, Env.SIGNET_ENABLED)
    } else if (!user && !Wallet.isLoggedIn() && sseConnected) {
      Wallet.disconnectUserSse()
      Wallet.disconnectWalletSse()
      setSseConnected(false)
    }
  }, [user, refetchUser])

  return {
    logout,
    closeLogin,
    login,
  }
}
