import { Ticker } from '@canonic/types'
import { atom, useAtom } from 'jotai'

const ticker = atom<Ticker>('BTC')
const notifications = atom(false)
const loggingIn = atom(false)

export const useTicker = () => useAtom(ticker)
export const useNotifications = () => useAtom(notifications)
export const useLoggingIn = () => useAtom(loggingIn)
